.hero_content {

	@include bp($point_9 + 1, min) {
		padding-top: 4.6rem;
	}

	@include bp($point_2, min) {
		width: 30.4%;
		margin-right: 2rem;
	}

	@include bp($point_3) {
		width: 100%;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		margin-bottom: 4rem;
		display: flex;
		justify-content: flex-start;
		align-items: flex-end;
		align-content: flex-end;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 2.8rem;
	}
}

.hero_content_in {
	width: 100%;

	@include bp($point_2, min) {
		margin-bottom: 3.6rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		max-width: 41rem;
		margin-right: auto;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 2.4rem;
	}
}

.hero_slider_block {
	@include bp($point_2, min) {
		width: 62%;
		margin-left: auto;
	}

	@include bp($point_3) {
		width: 100%;
	}
}

.hero_slider_block_top {
	position: relative;

	width: 100%;
	margin-bottom: 3.4rem;
}

.hero_decor {
	position: absolute;
	top: -6rem;
	right: -6rem;

	width: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: rgba(156, 12, 24, 0.5);
	}

	&:after {
		position: absolute;
		top: 4rem;
		right: 4rem;

		content: '';

		width: 100%;
		height: 100%;

		background-color: $white;
	}

	@include bp($point_3) {
		display: none;
	}
}

.hero_slider {
	z-index: 10;

	position: relative;
	width: 100%;

	overflow: hidden;

	background-color: $white;
}

.hero_slider_item {
	overflow: hidden;
	margin-right: -1px;
}

.hero_slider_item_in {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	position: relative;

	width: 100%;
	height: 42rem;
	padding: 2.6rem 3rem;

	@media (min-width: $point_2) and (max-width: $point_9) {
		height: 32rem;
	}

	@media (min-width: $point_5) and (max-width: $point_3) {
		height: 30rem;
	}

	@include bp($point_6) {
		height: 22rem;
		padding: .8rem 1.2rem;
	}
}

.hero_slider_text {
	width: 100%;
	padding: 2.2rem;

	color: #000;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.3;

	background-color: rgba(#fff, .9);

	span {
		color: #d3002b;
	}

	@include bp($point_6, min) {
		max-width: 31rem;
	}

	@include bp($point_2) {
		font-size: 1.5rem;
	}
}

.hero_slider_control_w {
	@extend %global_flex_block_row_wrap_flex-end_center;

	@include bp($point_4 - 1) {
		justify-content: center;
	}
}

.slider_control {
	@extend %transition_color;

	position: relative;

	display: block;

	width: 7.7rem;
	height: 2rem;

	color: $black;

	cursor: pointer;

	&:hover, &:active, &:focus {
		color: #d3002b;
	}

	&:first-child {
		margin-right: 3.2rem;
	}

	@include bp($point_4 - 1) {
		width: 5rem;
		height: 1.2rem;
	}
}