//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;

	@include bp($point_2 + 1, min) {
		padding-top: 10rem;
		padding-bottom: 10rem;
	}

	@include bp($point_2,  min) {
		padding-left: $gl_indent;
		padding-right: $gl_indent;
	}

	@media (min-width: $point_4) and (max-width: $point_2) {
		padding-top: 5rem;
		padding-bottom: 5rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		padding-left: $tablet_indent;
		padding-right: $tablet_indent;
	}

	@include bp($point_4 - 1) {
		padding: 6rem $mobile_indent;
	}


	font-size: 1rem;

	&.hero_mod {
		@include bp($point_9 + 1, min) {
			padding: 6rem $gl_indent 17.6rem;
		}
	}

	&.bg_mod {
		background-color: #efece9;
	}

	&.bottom_offset {
		@include bp($point_2 + 1, min) {
			padding-bottom: 40rem;
		}

		@media (min-width: $point_6 + 1) and (max-width: $point_2) {
			padding-bottom: 24rem;
		}

		@include bp($point_6) {
			padding-bottom: 12rem;
		}
	}

	&.offset_mod {

		@include bp($point_2, min) {
			padding: 3.6rem $gl_indent 0;
		}

		@media (min-width: $point_4) and (max-width: $point_3) {
			padding: 3.6rem $tablet_indent 0;
		}

		@include bp($point_4 - 1) {
			padding: 3.6rem $mobile_indent 0;
		}
	}

	&.decor_top_mod {
		&:before {
			position: absolute;
			top: 0;
			left: 0;

			content: '';

			width: 100%;
			height: 3.6rem;

			background-color: #000000;
		}
	}

	&.no_offset_mod {
		padding: 0 $gl_indent;

		@media (min-width: $point_4) and (max-width: $point_3) {
			padding-left: $tablet_indent;
			padding-right: $tablet_indent;
		}

		@include bp($point_4 - 1) {
			padding-left: $mobile_indent;
			padding-right: $mobile_indent;
		}
	}

	&.offset_top_mod {
		padding-top: 11rem;
	}


}


.section_in {
	z-index: 2;

	position: relative;

	max-width: $page_width;
	width: 100%;
	margin: 0 auto;

	@include bp($point_9 + 1 , min) {
		padding-left: 27rem;
	}

	&.size_mod {
		max-width: $page_width_2;
	}

	&.flex_mod {
		@extend %global_flex_block_row_wrap_flex-start;
	}

	&.form_mod {
		@extend %global_flex_block_row_wrap_center_center;

		@include bp($point_4, min) {
			min-height: 60rem;
		}
	}

	&.banner_mod {
		@extend %global_flex_block_row_wrap_flex-start_center;

		@include bp($point_4, min) {
			min-height: 28rem;
			padding: 4rem 0;
		}

		@include bp($point_4 - 1) {
			padding: 4rem 0 2.8rem;
		}
	}

	&.offset_mod {
		padding-left: 0;
	}

	&.offset_2_mod {
		@include bp($point_9 + 1, min) {
			padding-left: 15rem;
		}
	}

	&.menu_mod {
		@extend %global_flex_block_column_nowrap_flex-start;

		min-height: 100%;
		padding-top: 10rem;
		padding-bottom: 4.4rem;
	}
}

.section_title {
	width: 100%;

	color: #000000;
	font-size: 3.8rem;
	font-weight: 800;
	line-height: 1.3;
	text-transform: uppercase;

	@media (min-width: $point_4) and (max-width: $point_9) {
		font-size: 2.8rem;
	}

	@include bp($point_4 - 1) {
		font-size: 2.4rem;
	}

	&.size_mod {
		font-size: 2.8rem;
		font-weight: 700;
		text-transform: none;

		@include bp($point_1) {
			font-size: 1.8rem;
		}
	}

	&.size_2_mod {
		font-size: 2.8rem;
		font-weight: 500;
	}

	&.decor_mod {
		position: relative;

		&:before {
			position: absolute;
			top: 2.2rem;
			right: 100%;

			content: '';

			width: 50vw;
			height: 0.4rem;
			margin-right: 2.8rem;

			background-color: #d3002b;

			@include bp($point_1) {
				display: none;
			}
		}
	}

	&.white_mod {
		color: #ffffff;
	}

	&.offset_mod {
		margin-bottom: 2rem;
	}

	&.offset_2_mod {
		margin-bottom: 6rem;
	}

	&.center_text_mod {
		text-align: center;

		@include bp($point_4 - 1) {
			.section_form_block & {
				text-align: left;
			}
		}
	}
}


.section_subtitle {
	width: 100%;
	margin-bottom: 1.4rem;

	color: #000000;
	font-size: 1.8rem;
	font-weight: 700;
	text-transform: uppercase;
	opacity: 0.3;

	@media (min-width: $point_4) and (max-width: $point_9) {
		font-size: 1.5rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.3rem;
	}
}

.section_descr {
	width: 100%;

	color: #777777;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.6;

	@include bp($point_1) {
		font-size: 1.3rem;
	}

	p {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.center_text_mod {
		text-align: center;

		@include bp($point_4 - 1) {
			.section_form_block & {
				text-align: left;
			}
		}
	}

	&.white_mod {
		color: #ffffff;
	}

	&.color_mod {
		color: #555;
	}

	&.color_2_mod {
		color: #231717;
	}


	&.offset_mod {
		margin-bottom: 3.6rem;
	}

	&.size_mod {
		max-width: 48.2rem;
		margin: 0 auto 5.2rem;
		text-align: center;

		@include bp($point_4 - 1) {
			.section_form_block & {
				max-width: none;
				margin: 0 0 2.8rem;
			}
		}
	}

	&.work_mod {
		font-size: 1.7rem;

		@include bp($point_1) {
			font-size: 1.3rem;
		}
	}
}

.section_head {
	width: 100%;

	&.flex_mod {
		@extend %global_flex_block_row_wrap_flex-start;
	}

	&.center_content_mod {
		align-items: center;
		align-content: center;
	}

	&.offset_mod {
		margin-bottom: 6rem;
	}

	&.offset_2_mod {
		margin-bottom: 3.8rem;
	}
}

.section_head_link {
	@extend %transition_color;

	display: inline-flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	text-decoration: none;
	color: #777777;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.4;
	text-transform: uppercase;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: $accent;
	}
}

.section_head_col {
	&:first-child {
		.section_head.v1_mod & {
			@include bp($point_4, min) {
				width: 34.6%;
				margin-right: 2rem;
			}

			@include bp($point_4 - 1) {
				width: 100%;
				max-width: 29.6rem;
			}
		}
		.section_head.v2_mod & {
			max-width: 32rem;
			margin-right: 4rem;
		}
		.section_head.v3_mod & {
			@include bp($point_2, min) {
				max-width: 70rem;
				margin-right: 4rem;
			}

			@include bp($point_3) {
				width: 100%;
				margin-bottom: 2rem;
			}
		}
	}
	&:last-child {
		.section_head.v1_mod & {
			width: 56%;
			margin-left: auto;

			@include bp($point_4 - 1) {
				display: none;
			}
		}
	}
}

.section_bg_w {
	z-index: -1;

	position: absolute;
	left: 0;

	width: 100%;
	height: 100%;

	&:not(.pos_mod) {
		top: 0;
	}

	&.gradient_mod {
		&:before {
			position: absolute;
			top: 0;
			left: 0;

			content: '';

			width: 100%;
			height: 100%;

			background-image: $gradient_2;
		}
	}

	&.gradient_2_mod {
		z-index: 1;

		&:before {
			position: absolute;
			top: 0;
			left: 0;

			content: '';

			width: 100%;
			height: 100%;

			background: linear-gradient(to bottom, rgba(239,236,233,1) 0%,rgba(255,255,255,0) 40%);
		}
	}

	&.pos_mod {
		bottom: 0;

		@include bp($point_2 + 1, min) {
			height: 54.10%;
		}

		@media (min-width: $point_6 + 1) and (max-width: $point_2) {
			height: 24rem;
		}

		@include bp($point_6) {
			height: 12rem;
		}
	}

	&.pos_2_mod {
		top: 3.6rem;
		height: call(100% -3.6rem);
	}

	&.overlay_mod {
		&:before {
			position: absolute;
			top: 0;
			left: 0;

			content: '';

			width: 100%;
			height: 100%;

			background: #402200;

			opacity: .3;
		}
	}

	@media (min-width: $point_4) and (max-width: $point_2) {
		.project_slider_item & {
			height: 50rem;
		}
	}

	@include bp($point_4  - 1) {
		.project_slider_item & {
			height: 20rem;
		}
	}
}

.cover_bg {
	display: block;
	width: 100%;
	height: 100%;

	object-fit: cover;
}

.section_banner_content {
	width: 100%;
	max-width: 44.6rem;
}