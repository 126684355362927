.brand_slider {
	margin: 0 (-$gl_indent);

	.slick-track {
		display: flex;
		align-items: center;
		align-content: center;
	}
}

.brand_slider_item {
	width: auto;
	padding: 0 3em;

	@include bp($point_6) {
		padding: 0 1rem;
	}
}

.brand_slider_item_in {
	@extend %global_flex_block_row_wrap_center_center;

	width: 100%;
	height: 100%;
}

.brand_slider_img {
	display: block;
	max-width: 100%;
	max-height: 5.5em;
}