.news_list_w {
	@include bp($point_9 + 1, min) {
		margin-left: -18.6rem;
	}
}

.news_list {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	padding: 0;

	list-style: none;

	@include bp($point_1 + 1, min) {
		margin: 0 -9.8rem -4rem 0;
	}

	@include bp($point_1) {
		margin: 0 -3rem -3rem 0;
	}
}

.news_item {
	padding: 0 9.8rem 4rem 0;

	@include bp($point_2, min) {
		width: 33.33%;
	}

	@include bp($point_3) {
		width: 100%;
	}

	@include bp($point_1 + 1, min) {
		padding: 0 9.8rem 4rem 0;
	}

	@include bp($point_1) {
		padding: 0 3rem 3rem 0;
	}
}

.news_item_in {
	width: 100%;

	@media (min-width: $point_4) and (max-width: $point_3) {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		align-content: flex-start;
	}
}

.news_item_top {
	position: relative;

	width: 100%;
	margin-bottom: 2.6rem;
	padding: 1.2rem 1.2rem 0 0;

	&:before {
		z-index: -1;

		position: absolute;
		top: 0;
		right: 0;

		content: '';

		width: 17.4rem;
		height: 17.4rem;
		background-color: rgba(107, 128, 155, 0.2);

		@media (min-width: $point_4) and (max-width: $point_3) {
			height: 10rem;
		}

		@include bp($point_4 - 1) {
			height: 12rem;
		}
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		max-width: 28.3rem;
		margin-right: 3rem;
	}
}

.news_item_img_w {
	width: 100%;
	height: 18.4rem;
	border: 1rem solid $white;
	border-left: 0;
	//box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	@media (min-width: $point_4) and (max-width: $point_3) {
		height: 12.6rem;
	}
	@include bp($point_6) {
		height: 14rem;
	}
}

.news_item_text_w {
	width: 100%;
}

.news_item_title {
	width: 100%;
	margin-bottom: 1.4rem;

	color: #000000;
	font-size: 1.7rem;
	font-weight: 600;
	line-height: 1.4;

	@include bp($point_2) {
		font-size: 1.5rem;
	}
}

.news_item_descr {
	width: 100%;
	margin-bottom: 4rem;

	color: #777777;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.4;

	p {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include bp($point_2) {
		font-size: 1.3rem;
	}

	@include bp($point_4 - 1) {
		margin-bottom: 1.8rem;
	}
}