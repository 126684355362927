.portfolio_list_w {
	@include bp($point_2, min) {
		margin-left: -2.8rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		margin: 0 (-$tablet_indent);
	}

	@include bp($point_4 - 1) {
		margin: 0 (-$mobile_indent);
	}
}

.portfolio_list {
	margin: 0;
	padding: 0;

	list-style: none;
}

.portfolio_item {
	width: 100%;
	margin-bottom: 5.4rem;

	&:last-child {
		margin-bottom: 0;
	}

	@include bp($point_3) {
		margin-bottom: 0;
		padding-bottom: 2rem;
		background-color: $black;
	}
}

.portfolio_item_in {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	position: relative;
	width: 100%;

	&:before {
		position: absolute;
		top: 100%;
		left: 0;

		content: '';

		width: 100%;

		height: 1.8rem;
		background-color: #000;
	}

	@include bp($point_2, min) {
		min-height: 58.5rem;
	}
}

.portfolio_img_block {
	@include bp($point_2, min) {
		z-index: -1;

		position: absolute;
		top: 0;
		left: 0;
		right: -12rem;
		bottom: 0;
	}

	@include bp($point_3) {
		position: relative;
		height: 30rem;
		margin-bottom: -3rem;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background: linear-gradient(180deg, rgba(64, 34, 0, 0) 0%, rgba(64, 34, 0, 0.5) 100%),
	}
}

.portfolio_img_block_in {
	width: 100%;
	height: 100%;
}