.btn_base {
	@extend %blue_btn_1;
	@extend %btn_size_1;
}

.btn_base_2 {
	@extend %white_btn_1;
	@extend %btn_size_1;

	@include bp($point_6) {
		.section_form_block &,
		.fields_list_w & {
			width: 100%;
		}
	}
}

.social_list {
	@extend %global_flex_block_row_wrap_flex-start_center;

	padding: 0;

	&.footer_mod {
		margin: 0 -4.8rem -2rem 0;
	}
}

.social_item {
	.footer_mod & {
		padding: 0 4.8em 2rem 0;
	}
}

.social_link {
	@extend %transition_color;
	
	text-decoration: none;
	.footer_mod & {
		color: $white;

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #d3002b;
		}
	}
}

.breadcrumbs_list {
	@extend %global_flex_block_row_nowrap_flex-start;

	margin: 0 -4.4rem 2.2rem 0;
	padding: 0;

	list-style: none;
}

.breadcrumbs_item {
	position: relative;

	padding: 0 4.4rem 0 0;

	&:before {
		position: absolute;
		top: .7rem;
		right: 1.8rem;

		content: '';

		width: 4px;
		height: 4px;

		background: #B3B3B3;
	}

	&:last-child {
		&:before {
			display: none;
		}
	}
}

.breadcrumbs_link {
	@extend %transition_color;

	text-decoration: none;
	color: rgba(#000, .3);
	font-size: 1.5rem;
	font-weight: 400;
	text-transform: uppercase;

	&.current_mod {
		color: rgba(#000, .3);
		&:hover, &:focus {
			color: rgba(#000, .3);
			text-decoration: none;
		}
	}

	&:hover, &:focus {
		text-decoration: none;
		color: $accent;
	}

	@include bp($point_3) {
		font-size: 1.3rem;
	}
}