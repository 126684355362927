//------------------------------------------------------------layout
.header {
	@extend %transition_background;

	position: fixed;
	top: 0;
	left: 0;

	@include bp($point_2 + 1, min) {
		height: $height_header;
	}

	@include bp($point_2) {
		height: $height_header_2;
	}

	width: 100%;

	font-size: 1rem;

	will-change: transform;

	@include z-index(header);

	&.scroll_mod {
		background-color: $white;
	}
}

.header_in {
	@extend %global_flex_block_row_wrap_flex-start_center;

	width: 100%;
	height: 100%;
}

.header_logo_w {
	@extend %global_flex_block_row_wrap_center_center;

	height: 100%;
	padding: 1rem 2rem;

	background-color: $white;

	@include bp($point_2 + 1, min) {
		width: 28rem;
	}

	@include bp($point_2) {
		width: calc(100% - #{$height_header_2});
	}

	&.menu_mod {
		position: absolute;
		top: 0;
		left: 7.2rem;

		height: $height_header;
		background-color: transparent;
	}
}

.header_logo {
	@extend %transition_opacity;

	display: flex;

	@include bp($point_2 + 1, min) {
		width: 100%;
	}

	@include bp($point_2) {
		width: 18rem;
	}

	&:hover, &:active, &:focus {
		opacity: .8;
	}
}

.logo {
	display: block;
	width: 100%;
	height: auto;
}

//------------------------------------------------------------layout###
//---------------------------------------------trigger
.menu_trigger_w {
	z-index: 10;

	position: relative;

	@include bp($point_2 + 1, min) {
		height: $height_header;
		width: $height_header;
	}

	@include bp($point_2) {
		height: $height_header_2;
		width: $height_header_2;
	}


	background-color: #d3002b;
}

@mixin user_menu_butt_line {
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: .2rem;
	margin-top: -.1rem;
	background: $white;
}

.menu_trigger {
	z-index: 210;
	position: absolute;
	top: 50%;
	left: 50%;
	height: 1.8rem;
	width: 2.6rem;
	margin: -.9rem 0 0 -1.3rem;


	cursor: pointer;

	&:before {
		content: '';
		transform: translate(0, -.8rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&:after {
		content: '';
		transform: translate(0, .8rem);
		transition: all .3s ease;

		@include user_menu_butt_line;
	}
	&.active_mod {
		&:before {
			transform: (rotate(45deg) translate(0, 0));
		}

		&:after {
			transform: (rotate(-45deg) translate(0, 0));
		}
	}
}

.menu_trigger_decor {
	transition: all .3s ease;

	.active_mod & {
		opacity: 0;
		transform: translate(100%, 0);
	}

	@include user_menu_butt_line;
}

//---------------------------------------------trigger###
.header_menu {
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100vh;
	max-height: 100vh;

	background: linear-gradient(105.52deg, #BB0B2F 3.21%, #811743 38.98%, #671C4C 65.66%, #003070 100%);

	transform: translateY(-101%);
	opacity: 0;

	overflow-x: hidden;
	overflow-y: auto;

	transition: opacity .3s ease, transform .3s ease;
	will-change: transform, opacity;

	body.menu_open & {
		transform: translateY(0);
		opacity: 1;
	}

	@include bp($point_2, min) {
		padding: 0 $height_header;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		padding: 0 $height_header_2;
	}

	@include bp($point_4 - 1) {
		padding: 0 $mobile_indent;
	}
}

.header_menu_top {
	@extend %global_flex_block_row_wrap_flex-start;
	width: 100%;
	margin-bottom: 4rem;

	@include bp($point_3) {
		flex-direction: column;
	}
}

.main_menu_w {
	@include bp($point_2, min) {
		width: 34%;
		margin-right: 1rem;
	}

	@include bp($point_3) {
		width: 100%;
		order: 2;
		margin-bottom: 4.8rem;
	}
}

.header_search {
	position: relative;

	@include bp($point_2, min) {
		width: 44%;
		margin-left: auto;
	}

	@include bp($point_3) {
		order: 1;
		width: 100%;
		margin-bottom: 2.8rem;
	}

	.form_field.default_mod {
		padding-left: 0;
		border: 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
}

.header_lang_w {
	@include bp($point_2, min) {
		margin-left: 12.2rem;
	}

	@include bp($point_3) {
		order: 3;
	}
}

.header_lang_list {
	margin: 0;
	padding: 0;

	list-style: none;

	@include bp($point_3) {
		display: flex;
		justify-content: center;
	}
}

.header_lang_item {
	@include bp($point_2, min) {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include bp($point_3) {
		margin-right: 2rem;

		&:last-child {
			margin-right: 0;
		}
	}
}

.header_lang_link {
	text-decoration: none;

	color: rgba($white, .5);
	font-size: 1.7rem;
	font-weight: 500;

	&.active_mod {
		color: $white;
	}
}

.main_menu_list {
	margin: 0;
	padding: 0;

	list-style: none;
}

.main_menu_item {
	margin-bottom: 2.8rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.main_menu_link {
	@extend %transition_opacity;

	text-decoration: none;
	color: #ffffff;
	font-size: 3.8rem;
	font-weight: 600;

	&:hover, &:active, &:focus {
		text-decoration: none;
		opacity: .8;
	}

	@include bp($point_1) {
		font-size: 2rem;
	}
}

.header_menu_bottom {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;
	margin-top: auto;
}

.header_menu_phone_w {
	@extend %global_flex_block_column_nowrap_flex-start;

	@include bp($point_1 + 1, min) {
		width: 34%;
		margin-right: 1rem;
	}

	@include bp($point_1) {
		width: 100%;
		margin-bottom: 4rem;
	}
}

.header_menu_social {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_1 + 1, min) {
		width: 56.16%;
		margin-left: auto;
	}

	@include bp($point_1) {
		width: 100%;
	}
}

.header_phone_link {
	@extend %transition_opacity;

	text-decoration: none;
	opacity: 0.5;
	color: #ffffff;
	font-size: 2.8rem;
	font-weight: 600;

	&:hover, &:active, &:focus {
		text-decoration: none;
		opacity: 1;
	}

	@include bp($point_1) {
		font-size: 2rem;
	}
}

.header_phone_title {
	color: rgba(255, 255, 255, 0.5);
	font-size: 1.5rem;
	font-weight: 400;
}

.header_social_list_w {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_4, min) {
		margin-right: 2rem;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		margin-bottom: 3rem;
	}
}

.header_social_list {
	@extend %global_flex_block_row_wrap_flex-start_center;

	margin: 0 -4rem -2rem 0;
	padding: 0;

	list-style: none;
}

.header_social_item {
	padding: 0 4rem 2rem 0;
}

.header_social {
	@extend %global_flex_block_row_wrap_center_center;
	@extend %transition_opacity;

	position: relative;
	width: 3.8rem;
	height: 3.8rem;

	text-decoration: none;

	color: $white;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}

.header_social_title {
	margin-left: 1.8rem;

	opacity: 0.5;
	color: #ffffff;
	font-size: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;

	@include bp($point_3) {
		display: none;
	}
}

.header_presentation_w {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_4, min) {
		margin-left: auto;
	}
}

.header_presentation_img_w {
	@extend %global_flex_block_row_wrap_center_center;

	position: relative;

	width: 5.6rem;
	height: 5.6rem;
	margin-right: 2.2rem;

	border-radius: 50%;

	overflow: hidden;

	cursor: pointer;

	&:before {
		z-index: 2;

		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: $white;

		opacity: .5;
	}
}

.header_presentation_img {
	z-index: 1;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.header_presentation_title {
	opacity: 0.5;
	color: #ffffff;
	font-size: 1.5rem;
	font-weight: 600;
	text-transform: uppercase;
}

.header_presentation_subtitle {
	color: rgba(255, 255, 255, 0.5);
	font-size: 1.3rem;
	font-weight: 600;
	text-transform: uppercase;
}