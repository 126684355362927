.other_work_list {
	width: 100%;
}

.other_work_item {
	@extend %global_flex_block_row_wrap_flex-start_center;

	width: 100%;
	margin-bottom: 4rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.other_work_item_col {
	&:first-child {
		@include bp($point_4, min) {
			width: 20%;
		}

		@include bp($point_4 - 1) {
			width: 100%;
			margin-bottom: 2rem;
		}
	}

	&:last-child {
		@include bp($point_4, min) {
			width: 71.5%;
			margin-left: auto;
		}

		@include bp($point_4 - 1) {
			width: 100%;
		}
	}
}

.other_work_img_w {
	position: relative;

	width: 100%;
	height: 18rem;

	&:before {
		@extend %transition_opacity;
		
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background: linear-gradient(0deg, rgba(64, 34, 0, 0.3), rgba(64, 34, 0, 0.3));
	}

	&:after {
		@extend %transition_opacity;

		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		opacity: 0;

		background: linear-gradient(123.62deg, rgba(187, 11, 47, 0.5) 5.8%, rgba(129, 23, 67, 0.5) 40.61%, rgba(103, 28, 76, 0.5) 65.98%, rgba(0, 48, 112, 0.5) 100%);
	}

	&:hover, &:active, &:focus {
		&:before {
			opacity: 0;
		}
		&:after {
			opacity: 1;
		}
	}

	.project_control_w {
		position: absolute;
		top: 50%;
		left: 50%;

		transform: translate(-50%, -50%);
	}
}

.other_work_subtitle {
	color: #d3002b;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 1.4;
	text-transform: uppercase;
}

.other_work_title {
	color: #000000;
	font-size: 2.8rem;
	font-weight: 500;
	line-height: 1.4;
	text-transform: uppercase;

	@include bp($point_3) {
		font-size: 2.4rem;
	}
}