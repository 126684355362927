//---------------------------------------------icons
.icon_w {
	position: relative;

	display: block;

	&.subscribe_mod {
		width: 2.4rem;
		height: 2.4rem;


		color: #d3002b;

		@include bp($point_4, min) {
			position: absolute;
			right: 100%;
			margin-right: 2.8rem;
		}

		@include bp($point_4 - 1) {
			margin: 0 auto 1.2rem;
		}
	}

	&.copy_mod {
		width: 1.7rem;
		height: 1.9rem;
		margin-right: 1.4rem;

		color: #555555;

		@include bp($point_5) {
			margin: 0;
			width: 4.6rem;
			height: 5rem;
			order: 2;
		}
	}

	&.dev_mod {
		width: 4rem;
		height: 4rem;
		margin-right: 2rem;

		color: #222222;

		@include bp($point_2) {
			width: 3rem;
			height: 3rem;
			margin-right: 1rem;
		}
	}

	&.btn_decor_mod {
		width: 1.2rem;
		height: 1.1rem;
		margin-left: 2.4rem;
	}

	&.arrow_link_mod {
		width: 5.5rem;
		height: 1.6rem;
		margin-left: 1.8rem;
	}

	&.search_mod {
		position: absolute;
		top: 50%;
		right: 0;

		width: 2.4rem;
		height: 2.4rem;
		margin-top: -1.2rem;

		color: $white;
		opacity: .3;
	}

	&.social_decor_mod {
		@extend %transition_opacity;

		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		opacity: .5;

		.header_social:hover &, .header_social:active &, .header_social:focus & {
			opacity: 1;
		}
	}

	&.play_mod {
		z-index: 10;

		width: 1rem;
		height: 1.3rem;
		color: #d3002b;
	}
}

.icon {
	&.size_mod {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;
	}
}