.work_slider_w {
	@include bp($point_2, min) {
		margin: 0 -22.3vw 3.4rem -2.8rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		margin: 0 -22.3vw 3.4rem (-$tablet_indent);
	}

	@include bp($point_4 - 1) {
		margin: 0 (-$mobile_indent) 3.4rem;
	}
}
.work_slider {
	width: 100%;
}

.work_slider_item {
	@include bp($point_4, min) {
		padding-right: 2.8rem;
	}
}

.work_slider_item_in {
	width: 100%;

	@include bp($point_2, min) {
		height: 58rem;
	}

	@include bp($point_3) {
		height: 40rem;
	}
}

.work_slider_bottom {
	@extend %global_flex_block_row_wrap_flex-start_center;
}

.work_slider_counter {
	position: relative;

	width: 6rem;
	height: 6rem;
	
	white-space: nowrap;
}

.work_slider_counter_in {
	position: absolute;
	top: 50%;
	left: 50%;

	color: #000;
	font-size: 2.8rem;
	font-weight: 500;
	line-height: 1.43;
	text-transform: uppercase;

	transform: translate(-50%, -50%);

	@include bp($point_4 - 1) {
		font-size: 2rem;
	}
}

.work_slider_control_w {
	@extend %global_flex_block_row_wrap_flex-start_center;
	margin-left: auto;
}