.footer {
	width: 100%;
	min-height: $height_footer;
	flex: 0 0 auto;

	font-size: 1rem;

	will-change: transform;

	background-color: #000000;
}

.footer_top {
	width: 100%;
	padding: 2.8rem $gl_indent;
	background-color: #121212;

	@media (min-width: $point_2) and (max-width: $point_9) {
		padding-left: 11.6rem;
		padding-right: 8rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		padding-left: 8rem;
		padding-right: 6rem;
	}

	@include bp($point_4 - 1) {
		padding-left: $mobile_indent;
		padding-right: $mobile_indent;
	}
}

.footer_subscribe_w {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_9) {
		align-items: flex-start;
		align-content: flex-start;
	}
}

.footer_subscribe_title {
	position: relative;

	color: #ffffff;
	font-size: 1.9rem;
	font-weight: 700;
	line-height: 1.2;

	@media (min-width: $point_2) and (max-width: $point_9) {
		width: 100%;
		max-width: 12.6rem;
		font-size: 1.6rem;
	}

	@include bp($point_2, min) {
		margin-right: 3.4rem;
	}

	@include bp($point_3) {
		width: 100%;
		font-size: 1.8rem;
		margin-bottom: 2.6rem;
	}

	@include bp($point_4 - 1) {
		text-align: center;
	}
}


.fields_list_w {
	@extend %global_flex_block_row_wrap_flex-start;

	@include bp($point_2, min) {
		width: 71%;
		margin-left: auto;
	}

	@include bp($point_3) {
		width: 100%;
	}

	&.section_form_mod {
		display: block;
		width: 100%;
		margin:0 0 2.2rem;
	}
}

.fields_list_in {
	@include bp($point_1 + 1, min) {
		width: 54%;
	}
	@media (min-width: $point_4) and (max-width: $point_1) {
		width: 70%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		margin-bottom: 1.8rem;
	}

	@include bp($point_4, min) {
		margin-right: 1.8rem;
	}
}

.fields_list {
	@extend %global_flex_block_row_wrap_flex-start_center;

	margin: 0 -1.8rem -1.8rem 0;
	padding: 0;

	list-style: none;
}

.field_item {
	width: 50%;
	padding: 0 1.8rem 1.8rem 0;

	.fields_list_w.section_form_mod & {
		@include bp($point_4, min) {
			width: 33.33%;
		}
		@include bp($point_4 - 1) {
			width: 100%;
		}
	}

	@include bp($point_5) {
		width: 100%;
	}
}

.footer_subscribe_text {
	@include bp($point_9 + 1) {
		width: 24%;
		margin-left: auto;
	}

	@include bp($point_9) {
		width: 100%;
		margin-top: 1.8rem;
	}
}

.footer_subscribe_text_in {
	color: #555555;
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.2;

	a {
		@extend %transition_color;
		color: #555555;
		text-decoration: underline;

		&:hover, &:active, &:focus {
			color: #d3002b;
		}
	}

	@include bp($point_9) {
		width: 100%;
		max-width: 31.6rem;
	}
}

.footer_bottom {
	width: 100%;

	@include bp($point_2, min) {
		padding: 6.8rem $gl_indent 2.6rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		padding: 3.8rem $tablet_indent 4.8rem;
	}

	@include bp($point_4 - 1) {
		padding: 4.4rem $mobile_indent;
	}

}

.footer_nav {
	width: 100%;
	margin-bottom: 3.2rem;

	@include bp($point_4 - 1) {
		display: none;
	}
}

.footer_nav_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -3.4rem -2rem 0;
	padding: 0;

	list-style: none;
}

.footer_nav_item {
	padding: 0 3.4rem 2rem 0;
}

.footer_nav_link {
	@extend %transition_color;
	
	text-decoration: none;
	color: #ffffff;
	font-size: 1.7rem;
	font-weight: 400;

	&:hover, &:active, &:focus {
		text-decoration: none;
		color: #d3002b;
	}

	@include bp($point_2) {
		font-size: 1.3rem;
	}
}

.footer_social {
	@extend %global_flex_block_row_wrap_flex-start_center;

	width: 100%;
	margin-bottom: 7rem;

	@include bp($point_5) {
		flex-direction: column;
		margin-bottom: 4.4rem;
	}
}

.footer_social_title {
	margin-right: 4.6rem;
	color: #555555;
	font-size: 1.7rem;
	font-weight: 500;

	@include bp($point_5) {
		margin: 0 0 1.4rem;
	}
}

.footer_copy_w {
	@extend %global_flex_block_row_wrap_flex-start_center;

	a {
		@extend %transition_color;
		color: #555555;
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 1.4;

		&:hover, &:active, &:focus {
			text-decoration: none;
			color: #d3002b;
		}

		@include bp($point_2) {
			font-size: 1.3rem;
		}
	}

	@include bp($point_5) {
		flex-direction: column;
		text-align: center;
	}
}

.footer_copy_text {
	@extend %global_flex_block_row_wrap_flex-start_center;

	margin-right: 7.4rem;

	color: #555555;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.4;

	@include bp($point_2) {
		font-size: 1.3rem;
		margin-right: 6rem;
	}

	@include bp($point_3) {
		order: 2;
		margin-right: 0;
	}
}

.footer_dev_text {
	@extend %global_flex_block_row_wrap_flex-start_center;

	margin-left: auto;

	color: #555555;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.4;

	@include bp($point_2) {
		font-size: 1.3rem;
	}
}

.footer_copy_text_block {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_5) {
		flex-direction: column;
		align-content: center;
		align-items: center;
		margin-bottom: 2.4rem;
	}
}

.footer_copy_text_block_in {
	@extend %global_flex_block_row_wrap_flex-start_center;

	@include bp($point_3) {
		flex-direction: column;
		align-items: flex-start;
		align-content: flex-start;
	}

	@include bp($point_5) {
		order: 1;
		margin-bottom: 1.8rem;
	}
}