.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-arrow_l {
	font-size:(20/10)*1rem;
	width:(77/20)*1em;
}
.icon-arrow_r {
	font-size:(20/10)*1rem;
	width:(77/20)*1em;
}
.icon-bell {
	font-size:(28/10)*1rem;
	width:(25.66/28)*1em;
}
.icon-copy_logo {
	font-size:(17.84/10)*1rem;
	width:(17.04/17.84)*1em;
}
.icon-dev_logo {
	font-size:(41.66/10)*1rem;
	width:(39.08/41.66)*1em;
}
.icon-facebook {
	font-size:(16/10)*1rem;
	width:(8.31/16)*1em;
}
.icon-instagram {
	font-size:(16/10)*1rem;
	width:(16/16)*1em;
}
.icon-play {
	font-size:(11.26/10)*1rem;
	width:(9.75/11.26)*1em;
}
.icon-plus {
	font-size:(13/10)*1rem;
	width:(13/13)*1em;
}
.icon-question {
	font-size:(15/10)*1rem;
	width:(9/15)*1em;
}
.icon-search {
	font-size:(22.86/10)*1rem;
	width:(22.86/22.86)*1em;
}
.icon-shevron {
	font-size:(9.57/10)*1rem;
	width:(11.29/9.57)*1em;
}
.icon-sozial_decor {
	font-size:(38/10)*1rem;
	width:(38/38)*1em;
}
.icon-sozial_decor_2 {
	font-size:(38.5/10)*1rem;
	width:(38/38.5)*1em;
}
