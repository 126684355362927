.step_block {
	width: 100%;
	max-width: 99.8rem;

	@include bp($point_9) {
		margin: 0 auto;
	}
}

.step_block_list {
	@extend %global_flex_block_row_wrap_flex-start;

	padding: 0;

	list-style: none;

	@include bp($point_2, min) {
		margin: 0 -10rem -2rem 0;
	}
}

.step_block_item {
	z-index: 2;

	position: relative;

	width: 33.33%;


	//overflow: hidden;

	&:nth-child(even) {
		z-index: 1;
	}

	@include bp($point_2, min) {
		padding: 2rem 10rem 2rem 0;
	}

}

.item_decor_line_w {
	@extend %global_flex_block_row_wrap_flex-start_center;

	position: absolute;
	top: 3.4rem;
	left: 50%;

	width: calc(100% + .4rem);

	@include bp($point_2, min) {
		transform: translateX(calc(-50% - 5rem));
	}

	@include bp($point_3) {
		top: 1.6rem;
		transform: translateX(-50%);
	}
}

.item_decor_line {
	@extend %global_flex_block_row_wrap_space_between_center;

	width: 50%;

	span {
		display: block;
		width: 0.4rem;
		height: 0.4rem;
		background-color: #000000;

		@include bp($point_2, min) {
			.step_block_item:hover & {
				background-color: #d3002b;
			}
		}

		@include bp($point_3) {
			.step_block_item.active_tab & {
				background-color: #d3002b;
			}
		}
	}

	&:first-child {
		@include bp($point_4 - 1) {
			span:nth-child(2) {
				display: none;
			}
		}
	}

	&:last-child {
		margin-left: auto;

		@include bp($point_4 - 1) {
			span:nth-child(2) {
				display: none;
			}
		}
	}

	.step_block_item:first-child & {
		&:first-child {
			display: none;
		}
	}

	.step_block_item:last-child & {
		&:last-child {
			display: none;
		}
	}
}

.step_block_item_in {
	@extend %global_flex_block_column_nowrap_flex-start_center;

	width: 100%;
	text-align: center;

	@include bp($point_4 - 1) {
		.step_block_item:first-child & {
			align-items: flex-start;
			align-content: flex-start;
		}
		.step_block_item:last-child & {
			align-items: flex-end;
			align-content: flex-end;
		}
	}
}

.step_block_item_head {
	@extend %global_flex_block_column_nowrap_flex-start_center;

	width: 100%;
}

.step_block_number {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 3.4rem;
	height: 3.4rem;
	margin-bottom: 2.4rem;

	color: #ffffff;
	font-size: 1.8rem;
	font-weight: 500;
	line-height: 3.4rem;

	background-color: #000000;
	border-radius: 50%;

	transition: transform .3s ease, background-color .3s ease, color .3s ease;
	will-change: transform;

	@include bp($point_2, min) {
		.step_block_item_in:hover & {
			background-color: #d3002b;
			color: $white;
			transform: scale(1.52);
		}
	}

	@include bp($point_3) {
		.step_block_item.active_tab & {
			background-color: #d3002b;
			color: $white;
			transform: scale(1.52);
		}
	}

	@include bp($point_4 - 1) {
		.step_block_item.active_tab & {
			transform: none;
		}
	}
}

.step_block_title {
	@extend %transition_color;

	margin-bottom: 1.8rem;

	color: #000000;
	font-size: 2.4rem;
	font-weight: 500;
	line-height: 1.4;

	@include bp($point_2, min) {
		.step_block_item_in:hover & {
			color: #d3002b;
		}
	}

	@include bp($point_3) {
		.step_block_item.active_tab & {
			color: #d3002b;
		}
	}

	@media (min-width: $point_2) and (max-width: $point_1) {
		font-size: 1.8rem;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		font-size: 1.5rem;
	}

	@include bp($point_4 - 1) {
		font-size: 1.8rem;
		display: none;

		.step_block_item.active_tab & {
			display: block;
		}
	}
}

.step_block_descr_w {

	@include bp($point_2, min) {
		opacity: 0;
		pointer-events: none;
	}

	.step_block_item_in:hover & {
		opacity: 1;
		pointer-events: auto;
	}

	.step_block_item_in & {
		@include bp($point_3) {
			display: none;
		}
	}
}

.step_block_descr {
	margin-bottom: 1.8rem;

	color: #000000;
	font-size: 1.5rem;
	font-weight: 300;
	line-height: 1.4;

	p {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.more_link {
	display: inline-flex;
	justify-content: flex-start;
	align-content: center;
	align-items: center;

	text-decoration: none;
	color: #003070;
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 1.4;

	&:hover, &:active, &:focus {
		text-decoration: none;
	}
}


.steps_text_list {
	width: 100%;
	max-width: 55rem;
	margin: 3rem auto 0;

	@include bp($point_2, min) {
		display: none;
	}

	@include bp($point_4 - 1) {
		margin-top: 0;
	}
}

.steps_text_item {
	width: 100%;

	display: none;

	&.active_tab {
		display: block;
	}

	@include bp($point_4, min) {
		text-align: center;
	}
}