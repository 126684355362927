@mixin st_btn() {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	white-space: nowrap;

	cursor: pointer;

	&:hover, &:focus {
		text-decoration: none;
	}

}

//color extends
%blue_btn_1 {
	font-weight: 600;
	color: $white;
	background-color: #003070;
	transition: .3s ease;

	@include st_btn();

	&:hover {
		background: darken(#003070, 10%);
	}
}

%white_btn_1 {
	font-weight: 600;
	color: #003070;
	background-color: $white;
	transition: .3s ease;

	@include st_btn();

	&:hover {
		background: darken($white, 10%);
	}
}

//size extends
%btn_size_1 {
	height: 4.8rem;
	padding: 0 2.4rem;

	line-height: 4.8rem;
	font-size: 1.5rem;

	border-radius: $gl_radius;

	&.size_mod {
		min-width: 21.8rem;
	}
}