.work_year_info {
	opacity: 0.2;
	font-weight: 700;
	text-transform: uppercase;

	@include bp($point_2, min) {
		margin-left: auto;
		font-size: 3.8rem;
	}

	@include bp($point_3) {
		font-size: 2.8rem;
	}
}

.work_info_w {
	@extend %global_flex_block_row_wrap_flex-start;

	&.offset_mod {
		margin-bottom: 6rem;
	}
}

.work_info_col {
	&:first-child {
		@include bp($point_2, min) {
			width: 31.4em;
			margin-right: 1rem;
		}

		@include bp($point_3) {
			width: 100%;
			margin-bottom: 2rem;
		}
	}

	&:last-child {
		@include bp($point_2, min) {
			width: 58.8%;
			margin-left: auto;
		}

		@include bp($point_3) {
			width: 100%;
		}
	}
}

.work_info_list {
	margin: 0;
	padding: 0;

	list-style: none;
}

.work_info_item {
	margin-bottom: 2rem;

	color: #003070;
	font-size: 1.2rem;
	font-weight: 700;
	text-transform: uppercase;

	&:last-child {
		margin-bottom: 0;
	}
}

.work_img_w {
	@include bp($point_2, min) {
		margin: 0 0 10rem -2.8rem;
	}

	@include bp($point_3) {
		margin-bottom: 6rem;
	}
}

.work_img {
	width: 100%;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}