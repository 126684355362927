.section_form_block {
	width: 100%;
	max-width: 70rem;
}

.section_form_bottom {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	width: 100%;
}

.section_form_bottom_text {
	width: 66.66%;
	margin-right: auto;

	@include bp($point_4 - 1) {
		order: 2;
		margin: 2.4rem 0 0;
	}
}

.section_form_bottom_text_in {
	width: 100%;
	max-width: 36rem;

	color: rgba(#fff, .5);
	font-size: 1.3rem;
	font-weight: 400;
	line-height: 1.2;

	a {
		text-decoration: underline;
		color: rgba(#fff, .5);
	}
}