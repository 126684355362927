@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.header_menu_top, .header_menu_bottom, .fields_list_w, .footer_nav_list, .section_in.flex_mod, .section_head.flex_mod, .step_block_list, .project_info_col:first-child, .project_text_slider_item_in, .work_info_w {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.project_info_w, .news_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch; }

.social_list, .header_in, .header_menu_social, .header_social_list_w, .header_social_list, .header_presentation_w, .footer_subscribe_w, .fields_list, .footer_social, .footer_copy_w, .footer_copy_text, .footer_dev_text, .footer_copy_text_block, .footer_copy_text_block_in, .section_in.banner_mod, .item_decor_line_w, .project_slider_dots, .work_slider_bottom, .work_slider_control_w, .other_work_item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center; }

.hero_slider_item_in, .project_slider_item_content, .section_form_bottom, .portfolio_item_in {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-end;
  align-items: flex-end; }

.hero_slider_control_w {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-content: center;
  align-items: center; }

.header_logo_w, .header_social, .header_presentation_img_w, .section_in.form_mod, .brand_slider_item_in, .slick-dots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center; }

.item_decor_line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center; }

.breadcrumbs_list {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.header_menu_phone_w, .section_in.menu_mod {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.step_block_item_in, .step_block_item_head {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center; }

.project_control_w {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: center; }

.header_logo, .main_menu_link, .header_phone_link, .header_social, .icon_w.social_decor_mod, .project_slider_item_info_in, .other_work_img_w:before, .other_work_img_w:after {
  transition: opacity .3s ease;
  will-change: opacity; }

.header {
  transition: background .3s ease;
  will-change: background; }

.social_link, .breadcrumbs_link, .footer_subscribe_text_in a, .footer_nav_link, .footer_copy_w a, .section_head_link, .slider_control, .step_block_title, .project_link {
  transition: color .3s ease;
  will-change: color; }

.btn_base {
  font-weight: 600;
  color: #fff;
  background-color: #003070;
  transition: .3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer; }
  .btn_base:hover, .btn_base:focus {
    text-decoration: none; }
  .btn_base:hover {
    background: #001a3d; }

.btn_base_2 {
  font-weight: 600;
  color: #003070;
  background-color: #fff;
  transition: .3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  cursor: pointer; }
  .btn_base_2:hover, .btn_base_2:focus {
    text-decoration: none; }
  .btn_base_2:hover {
    background: #e6e6e6; }

.btn_base, .btn_base_2 {
  height: 4.8rem;
  padding: 0 2.4rem;
  line-height: 4.8rem;
  font-size: 1.5rem;
  border-radius: 0; }
  .size_mod.btn_base, .size_mod.btn_base_2 {
    min-width: 21.8rem; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video, button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  box-sizing: border-box; }
  html:before, html:after, body:before, body:after, div:before, div:after, span:before, span:after, object:before, object:after, iframe:before, iframe:after,
  h1:before,
  h1:after, h2:before, h2:after, h3:before, h3:after, h4:before, h4:after, h5:before, h5:after, h6:before, h6:after, p:before, p:after, blockquote:before, blockquote:after, pre:before, pre:after,
  abbr:before,
  abbr:after, address:before, address:after, cite:before, cite:after, code:before, code:after,
  del:before,
  del:after, dfn:before, dfn:after, em:before, em:after, img:before, img:after, ins:before, ins:after, kbd:before, kbd:after, q:before, q:after, samp:before, samp:after,
  small:before,
  small:after, strong:before, strong:after, var:before, var:after,
  b:before,
  b:after, i:before, i:after,
  dl:before,
  dl:after, dt:before, dt:after, dd:before, dd:after, ol:before, ol:after, ul:before, ul:after, li:before, li:after,
  fieldset:before,
  fieldset:after, form:before, form:after, label:before, label:after, legend:before, legend:after,
  article:before,
  article:after, aside:before, aside:after, canvas:before, canvas:after, details:before, details:after, figcaption:before, figcaption:after, figure:before, figure:after,
  footer:before,
  footer:after, header:before, header:after, hgroup:before, hgroup:after, menu:before, menu:after, nav:before, nav:after, section:before, section:after, summary:before, summary:after,
  time:before,
  time:after, mark:before, mark:after, audio:before, audio:after, video:before, video:after, button:before, button:after {
    box-sizing: border-box; }

sub,
sup {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
  box-sizing: border-box; }
  sub:before, sub:after,
  sup:before,
  sup:after {
    box-sizing: border-box; }

a:before, a:after {
  box-sizing: border-box; }

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: none; }

q:before, q:after {
  content: none; }

:focus {
  outline: 0; }

input[type="text"], textarea {
  -webkit-appearance: none; }
  input[type="text"]::-ms-clear, textarea::-ms-clear {
    display: none; }

input,
textarea {
  border-radius: 0;
  box-sizing: border-box; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: #fff; }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: #fff; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #fff; }
  input:focus::-webkit-input-placeholder,
  textarea:focus::-webkit-input-placeholder {
    color: transparent; }
  input:focus::-moz-placeholder,
  textarea:focus::-moz-placeholder {
    color: transparent; }
  input:focus:-ms-input-placeholder,
  textarea:focus:-ms-input-placeholder {
    color: transparent; }

textarea {
  resize: none; }

ins {
  text-decoration: none; }

del {
  text-decoration: line-through; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box;
  word-wrap: break-word; }

:root {
  --full-screen: calc(var(--vh, 1vh) * 100); }

html {
  height: 100%;
  font: 400 10px/1.33 "Montserrat", sans-serif; }

body {
  position: relative;
  display: flex;
  height: 100%;
  font-size: 1.6rem;
  color: #000;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff; }
  @supports (display: grid) {
    body {
      display: block; } }
  body.menu_open {
    overflow: hidden; }

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto !important;
  overflow-x: hidden; }

.base {
  width: 100%;
  position: relative;
  padding: 7.2rem 0 0;
  flex: 1 0 auto; }
  @media screen and (max-width: 1024px) {
    .base {
      padding-top: 6rem; } }
  body.work_mod .base {
    padding-top: 0; }

a {
  text-decoration: none;
  color: #4aa3df; }
  a:hover, a:focus {
    text-decoration: underline; }

.elements_list {
  padding: 3rem; }

@media screen and (max-width: 480px) {
  .section_form_block .btn_base_2,
  .fields_list_w .btn_base_2 {
    width: 100%; } }

.social_list {
  padding: 0; }
  .social_list.footer_mod {
    margin: 0 -4.8rem -2rem 0; }

.footer_mod .social_item {
  padding: 0 4.8em 2rem 0; }

.social_link {
  text-decoration: none; }
  .footer_mod .social_link {
    color: #fff; }
    .footer_mod .social_link:hover, .footer_mod .social_link:active, .footer_mod .social_link:focus {
      text-decoration: none;
      color: #d3002b; }

.breadcrumbs_list {
  margin: 0 -4.4rem 2.2rem 0;
  padding: 0;
  list-style: none; }

.breadcrumbs_item {
  position: relative;
  padding: 0 4.4rem 0 0; }
  .breadcrumbs_item:before {
    position: absolute;
    top: .7rem;
    right: 1.8rem;
    content: '';
    width: 4px;
    height: 4px;
    background: #B3B3B3; }
  .breadcrumbs_item:last-child:before {
    display: none; }

.breadcrumbs_link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.3);
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase; }
  .breadcrumbs_link.current_mod {
    color: rgba(0, 0, 0, 0.3); }
    .breadcrumbs_link.current_mod:hover, .breadcrumbs_link.current_mod:focus {
      color: rgba(0, 0, 0, 0.3);
      text-decoration: none; }
  .breadcrumbs_link:hover, .breadcrumbs_link:focus {
    text-decoration: none;
    color: #BB0B2F; }
  @media screen and (max-width: 1023px) {
    .breadcrumbs_link {
      font-size: 1.3rem; } }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  font-size: 1rem;
  will-change: transform;
  z-index: 100; }
  @media screen and (min-width: 1025px) {
    .header {
      height: 7.2rem; } }
  @media screen and (max-width: 1024px) {
    .header {
      height: 6rem; } }
  .header.scroll_mod {
    background-color: #fff; }

.header_in {
  width: 100%;
  height: 100%; }

.header_logo_w {
  height: 100%;
  padding: 1rem 2rem;
  background-color: #fff; }
  @media screen and (min-width: 1025px) {
    .header_logo_w {
      width: 28rem; } }
  @media screen and (max-width: 1024px) {
    .header_logo_w {
      width: calc(100% - 6rem); } }
  .header_logo_w.menu_mod {
    position: absolute;
    top: 0;
    left: 7.2rem;
    height: 7.2rem;
    background-color: transparent; }

.header_logo {
  display: flex; }
  @media screen and (min-width: 1025px) {
    .header_logo {
      width: 100%; } }
  @media screen and (max-width: 1024px) {
    .header_logo {
      width: 18rem; } }
  .header_logo:hover, .header_logo:active, .header_logo:focus {
    opacity: .8; }

.logo {
  display: block;
  width: 100%;
  height: auto; }

.menu_trigger_w {
  z-index: 10;
  position: relative;
  background-color: #d3002b; }
  @media screen and (min-width: 1025px) {
    .menu_trigger_w {
      height: 7.2rem;
      width: 7.2rem; } }
  @media screen and (max-width: 1024px) {
    .menu_trigger_w {
      height: 6rem;
      width: 6rem; } }

.menu_trigger {
  z-index: 210;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 1.8rem;
  width: 2.6rem;
  margin: -.9rem 0 0 -1.3rem;
  cursor: pointer; }
  .menu_trigger:before {
    content: '';
    transform: translate(0, -0.8rem);
    transition: all .3s ease;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .2rem;
    margin-top: -.1rem;
    background: #fff; }
  .menu_trigger:after {
    content: '';
    transform: translate(0, 0.8rem);
    transition: all .3s ease;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: .2rem;
    margin-top: -.1rem;
    background: #fff; }
  .menu_trigger.active_mod:before {
    transform: rotate(45deg) translate(0, 0); }
  .menu_trigger.active_mod:after {
    transform: rotate(-45deg) translate(0, 0); }

.menu_trigger_decor {
  transition: all .3s ease;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: .2rem;
  margin-top: -.1rem;
  background: #fff; }
  .active_mod .menu_trigger_decor {
    opacity: 0;
    transform: translate(100%, 0); }

.header_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
  background: linear-gradient(105.52deg, #BB0B2F 3.21%, #811743 38.98%, #671C4C 65.66%, #003070 100%);
  transform: translateY(-101%);
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: opacity .3s ease, transform .3s ease;
  will-change: transform, opacity; }
  body.menu_open .header_menu {
    transform: translateY(0);
    opacity: 1; }
  @media screen and (min-width: 1024px) {
    .header_menu {
      padding: 0 7.2rem; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .header_menu {
      padding: 0 6rem; } }
  @media screen and (max-width: 767px) {
    .header_menu {
      padding: 0 1.2rem; } }

.header_menu_top {
  width: 100%;
  margin-bottom: 4rem; }
  @media screen and (max-width: 1023px) {
    .header_menu_top {
      flex-direction: column; } }

@media screen and (min-width: 1024px) {
  .main_menu_w {
    width: 34%;
    margin-right: 1rem; } }

@media screen and (max-width: 1023px) {
  .main_menu_w {
    width: 100%;
    order: 2;
    margin-bottom: 4.8rem; } }

.header_search {
  position: relative; }
  @media screen and (min-width: 1024px) {
    .header_search {
      width: 44%;
      margin-left: auto; } }
  @media screen and (max-width: 1023px) {
    .header_search {
      order: 1;
      width: 100%;
      margin-bottom: 2.8rem; } }
  .header_search .form_field.default_mod {
    padding-left: 0;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

@media screen and (min-width: 1024px) {
  .header_lang_w {
    margin-left: 12.2rem; } }

@media screen and (max-width: 1023px) {
  .header_lang_w {
    order: 3; } }

.header_lang_list {
  margin: 0;
  padding: 0;
  list-style: none; }
  @media screen and (max-width: 1023px) {
    .header_lang_list {
      display: flex;
      justify-content: center; } }

@media screen and (min-width: 1024px) {
  .header_lang_item {
    margin-bottom: 2rem; }
    .header_lang_item:last-child {
      margin-bottom: 0; } }

@media screen and (max-width: 1023px) {
  .header_lang_item {
    margin-right: 2rem; }
    .header_lang_item:last-child {
      margin-right: 0; } }

.header_lang_link {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.7rem;
  font-weight: 500; }
  .header_lang_link.active_mod {
    color: #fff; }

.main_menu_list {
  margin: 0;
  padding: 0;
  list-style: none; }

.main_menu_item {
  margin-bottom: 2.8rem; }
  .main_menu_item:last-child {
    margin-bottom: 0; }

.main_menu_link {
  text-decoration: none;
  color: #ffffff;
  font-size: 3.8rem;
  font-weight: 600; }
  .main_menu_link:hover, .main_menu_link:active, .main_menu_link:focus {
    text-decoration: none;
    opacity: .8; }
  @media screen and (max-width: 1200px) {
    .main_menu_link {
      font-size: 2rem; } }

.header_menu_bottom {
  width: 100%;
  margin-top: auto; }

@media screen and (min-width: 1201px) {
  .header_menu_phone_w {
    width: 34%;
    margin-right: 1rem; } }

@media screen and (max-width: 1200px) {
  .header_menu_phone_w {
    width: 100%;
    margin-bottom: 4rem; } }

@media screen and (min-width: 1201px) {
  .header_menu_social {
    width: 56.16%;
    margin-left: auto; } }

@media screen and (max-width: 1200px) {
  .header_menu_social {
    width: 100%; } }

.header_phone_link {
  text-decoration: none;
  opacity: 0.5;
  color: #ffffff;
  font-size: 2.8rem;
  font-weight: 600; }
  .header_phone_link:hover, .header_phone_link:active, .header_phone_link:focus {
    text-decoration: none;
    opacity: 1; }
  @media screen and (max-width: 1200px) {
    .header_phone_link {
      font-size: 2rem; } }

.header_phone_title {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.5rem;
  font-weight: 400; }

@media screen and (min-width: 768px) {
  .header_social_list_w {
    margin-right: 2rem; } }

@media screen and (max-width: 767px) {
  .header_social_list_w {
    width: 100%;
    margin-bottom: 3rem; } }

.header_social_list {
  margin: 0 -4rem -2rem 0;
  padding: 0;
  list-style: none; }

.header_social_item {
  padding: 0 4rem 2rem 0; }

.header_social {
  position: relative;
  width: 3.8rem;
  height: 3.8rem;
  text-decoration: none;
  color: #fff; }
  .header_social:hover, .header_social:active, .header_social:focus {
    text-decoration: none; }

.header_social_title {
  margin-left: 1.8rem;
  opacity: 0.5;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase; }
  @media screen and (max-width: 1023px) {
    .header_social_title {
      display: none; } }

@media screen and (min-width: 768px) {
  .header_presentation_w {
    margin-left: auto; } }

.header_presentation_img_w {
  position: relative;
  width: 5.6rem;
  height: 5.6rem;
  margin-right: 2.2rem;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer; }
  .header_presentation_img_w:before {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: .5; }

.header_presentation_img {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.header_presentation_title {
  opacity: 0.5;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase; }

.header_presentation_subtitle {
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase; }

.footer {
  width: 100%;
  min-height: 38rem;
  flex: 0 0 auto;
  font-size: 1rem;
  will-change: transform;
  background-color: #000000; }

.footer_top {
  width: 100%;
  padding: 2.8rem 6rem;
  background-color: #121212; }
  @media (min-width: 1024px) and (max-width: 1600px) {
    .footer_top {
      padding-left: 11.6rem;
      padding-right: 8rem; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .footer_top {
      padding-left: 8rem;
      padding-right: 6rem; } }
  @media screen and (max-width: 767px) {
    .footer_top {
      padding-left: 1.2rem;
      padding-right: 1.2rem; } }

@media screen and (max-width: 1600px) {
  .footer_subscribe_w {
    align-items: flex-start;
    align-content: flex-start; } }

.footer_subscribe_title {
  position: relative;
  color: #ffffff;
  font-size: 1.9rem;
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1024px) and (max-width: 1600px) {
    .footer_subscribe_title {
      width: 100%;
      max-width: 12.6rem;
      font-size: 1.6rem; } }
  @media screen and (min-width: 1024px) {
    .footer_subscribe_title {
      margin-right: 3.4rem; } }
  @media screen and (max-width: 1023px) {
    .footer_subscribe_title {
      width: 100%;
      font-size: 1.8rem;
      margin-bottom: 2.6rem; } }
  @media screen and (max-width: 767px) {
    .footer_subscribe_title {
      text-align: center; } }

@media screen and (min-width: 1024px) {
  .fields_list_w {
    width: 71%;
    margin-left: auto; } }

@media screen and (max-width: 1023px) {
  .fields_list_w {
    width: 100%; } }

.fields_list_w.section_form_mod {
  display: block;
  width: 100%;
  margin: 0 0 2.2rem; }

@media screen and (min-width: 1201px) {
  .fields_list_in {
    width: 54%; } }

@media (min-width: 768px) and (max-width: 1200px) {
  .fields_list_in {
    width: 70%; } }

@media screen and (max-width: 767px) {
  .fields_list_in {
    width: 100%;
    margin-bottom: 1.8rem; } }

@media screen and (min-width: 768px) {
  .fields_list_in {
    margin-right: 1.8rem; } }

.fields_list {
  margin: 0 -1.8rem -1.8rem 0;
  padding: 0;
  list-style: none; }

.field_item {
  width: 50%;
  padding: 0 1.8rem 1.8rem 0; }
  @media screen and (min-width: 768px) {
    .fields_list_w.section_form_mod .field_item {
      width: 33.33%; } }
  @media screen and (max-width: 767px) {
    .fields_list_w.section_form_mod .field_item {
      width: 100%; } }
  @media screen and (max-width: 640px) {
    .field_item {
      width: 100%; } }

@media screen and (max-width: 1601px) {
  .footer_subscribe_text {
    width: 24%;
    margin-left: auto; } }

@media screen and (max-width: 1600px) {
  .footer_subscribe_text {
    width: 100%;
    margin-top: 1.8rem; } }

.footer_subscribe_text_in {
  color: #555555;
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.2; }
  .footer_subscribe_text_in a {
    color: #555555;
    text-decoration: underline; }
    .footer_subscribe_text_in a:hover, .footer_subscribe_text_in a:active, .footer_subscribe_text_in a:focus {
      color: #d3002b; }
  @media screen and (max-width: 1600px) {
    .footer_subscribe_text_in {
      width: 100%;
      max-width: 31.6rem; } }

.footer_bottom {
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .footer_bottom {
      padding: 6.8rem 6rem 2.6rem; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .footer_bottom {
      padding: 3.8rem 2.4rem 4.8rem; } }
  @media screen and (max-width: 767px) {
    .footer_bottom {
      padding: 4.4rem 1.2rem; } }

.footer_nav {
  width: 100%;
  margin-bottom: 3.2rem; }
  @media screen and (max-width: 767px) {
    .footer_nav {
      display: none; } }

.footer_nav_list {
  margin: 0 -3.4rem -2rem 0;
  padding: 0;
  list-style: none; }

.footer_nav_item {
  padding: 0 3.4rem 2rem 0; }

.footer_nav_link {
  text-decoration: none;
  color: #ffffff;
  font-size: 1.7rem;
  font-weight: 400; }
  .footer_nav_link:hover, .footer_nav_link:active, .footer_nav_link:focus {
    text-decoration: none;
    color: #d3002b; }
  @media screen and (max-width: 1024px) {
    .footer_nav_link {
      font-size: 1.3rem; } }

.footer_social {
  width: 100%;
  margin-bottom: 7rem; }
  @media screen and (max-width: 640px) {
    .footer_social {
      flex-direction: column;
      margin-bottom: 4.4rem; } }

.footer_social_title {
  margin-right: 4.6rem;
  color: #555555;
  font-size: 1.7rem;
  font-weight: 500; }
  @media screen and (max-width: 640px) {
    .footer_social_title {
      margin: 0 0 1.4rem; } }

.footer_copy_w a {
  color: #555555;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4; }
  .footer_copy_w a:hover, .footer_copy_w a:active, .footer_copy_w a:focus {
    text-decoration: none;
    color: #d3002b; }
  @media screen and (max-width: 1024px) {
    .footer_copy_w a {
      font-size: 1.3rem; } }

@media screen and (max-width: 640px) {
  .footer_copy_w {
    flex-direction: column;
    text-align: center; } }

.footer_copy_text {
  margin-right: 7.4rem;
  color: #555555;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4; }
  @media screen and (max-width: 1024px) {
    .footer_copy_text {
      font-size: 1.3rem;
      margin-right: 6rem; } }
  @media screen and (max-width: 1023px) {
    .footer_copy_text {
      order: 2;
      margin-right: 0; } }

.footer_dev_text {
  margin-left: auto;
  color: #555555;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4; }
  @media screen and (max-width: 1024px) {
    .footer_dev_text {
      font-size: 1.3rem; } }

@media screen and (max-width: 640px) {
  .footer_copy_text_block {
    flex-direction: column;
    align-content: center;
    align-items: center;
    margin-bottom: 2.4rem; } }

@media screen and (max-width: 1023px) {
  .footer_copy_text_block_in {
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start; } }

@media screen and (max-width: 640px) {
  .footer_copy_text_block_in {
    order: 1;
    margin-bottom: 1.8rem; } }

.form_field.default_mod {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 0 2rem;
  font-size: 1.5rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  box-shadow: none;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 0;
  background-color: transparent; }
  .form_field.default_mod::-webkit-input-placeholder {
    color: #fff;
    opacity: .3; }
  .form_field.default_mod::-moz-placeholder {
    color: #fff;
    opacity: .3; }
  .form_field.default_mod:-ms-input-placeholder {
    color: #fff;
    opacity: .3; }

.form_cell.hidden_field {
  display: none; }

.form_cell_title.hline_hide_mod {
  display: none; }

.form_field_wrap {
  position: relative; }

.section {
  position: relative;
  width: 100%;
  font-size: 1rem; }
  @media screen and (min-width: 1025px) {
    .section {
      padding-top: 10rem;
      padding-bottom: 10rem; } }
  @media screen and (min-width: 1024px) {
    .section {
      padding-left: 6rem;
      padding-right: 6rem; } }
  @media (min-width: 768px) and (max-width: 1024px) {
    .section {
      padding-top: 5rem;
      padding-bottom: 5rem; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .section {
      padding-left: 2.4rem;
      padding-right: 2.4rem; } }
  @media screen and (max-width: 767px) {
    .section {
      padding: 6rem 1.2rem; } }
  @media screen and (min-width: 1601px) {
    .section.hero_mod {
      padding: 6rem 6rem 17.6rem; } }
  .section.bg_mod {
    background-color: #efece9; }
  @media screen and (min-width: 1025px) {
    .section.bottom_offset {
      padding-bottom: 40rem; } }
  @media (min-width: 481px) and (max-width: 1024px) {
    .section.bottom_offset {
      padding-bottom: 24rem; } }
  @media screen and (max-width: 480px) {
    .section.bottom_offset {
      padding-bottom: 12rem; } }
  @media screen and (min-width: 1024px) {
    .section.offset_mod {
      padding: 3.6rem 6rem 0; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .section.offset_mod {
      padding: 3.6rem 2.4rem 0; } }
  @media screen and (max-width: 767px) {
    .section.offset_mod {
      padding: 3.6rem 1.2rem 0; } }
  .section.decor_top_mod:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 3.6rem;
    background-color: #000000; }
  .section.no_offset_mod {
    padding: 0 6rem; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .section.no_offset_mod {
        padding-left: 2.4rem;
        padding-right: 2.4rem; } }
    @media screen and (max-width: 767px) {
      .section.no_offset_mod {
        padding-left: 1.2rem;
        padding-right: 1.2rem; } }
  .section.offset_top_mod {
    padding-top: 11rem; }

.section_in {
  z-index: 2;
  position: relative;
  max-width: 147rem;
  width: 100%;
  margin: 0 auto; }
  @media screen and (min-width: 1601px) {
    .section_in {
      padding-left: 27rem; } }
  .section_in.size_mod {
    max-width: 136rem; }
  @media screen and (min-width: 768px) {
    .section_in.form_mod {
      min-height: 60rem; } }
  @media screen and (min-width: 768px) {
    .section_in.banner_mod {
      min-height: 28rem;
      padding: 4rem 0; } }
  @media screen and (max-width: 767px) {
    .section_in.banner_mod {
      padding: 4rem 0 2.8rem; } }
  .section_in.offset_mod {
    padding-left: 0; }
  @media screen and (min-width: 1601px) {
    .section_in.offset_2_mod {
      padding-left: 15rem; } }
  .section_in.menu_mod {
    min-height: 100%;
    padding-top: 10rem;
    padding-bottom: 4.4rem; }

.section_title {
  width: 100%;
  color: #000000;
  font-size: 3.8rem;
  font-weight: 800;
  line-height: 1.3;
  text-transform: uppercase; }
  @media (min-width: 768px) and (max-width: 1600px) {
    .section_title {
      font-size: 2.8rem; } }
  @media screen and (max-width: 767px) {
    .section_title {
      font-size: 2.4rem; } }
  .section_title.size_mod {
    font-size: 2.8rem;
    font-weight: 700;
    text-transform: none; }
    @media screen and (max-width: 1200px) {
      .section_title.size_mod {
        font-size: 1.8rem; } }
  .section_title.size_2_mod {
    font-size: 2.8rem;
    font-weight: 500; }
  .section_title.decor_mod {
    position: relative; }
    .section_title.decor_mod:before {
      position: absolute;
      top: 2.2rem;
      right: 100%;
      content: '';
      width: 50vw;
      height: 0.4rem;
      margin-right: 2.8rem;
      background-color: #d3002b; }
      @media screen and (max-width: 1200px) {
        .section_title.decor_mod:before {
          display: none; } }
  .section_title.white_mod {
    color: #ffffff; }
  .section_title.offset_mod {
    margin-bottom: 2rem; }
  .section_title.offset_2_mod {
    margin-bottom: 6rem; }
  .section_title.center_text_mod {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .section_form_block .section_title.center_text_mod {
        text-align: left; } }

.section_subtitle {
  width: 100%;
  margin-bottom: 1.4rem;
  color: #000000;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0.3; }
  @media (min-width: 768px) and (max-width: 1600px) {
    .section_subtitle {
      font-size: 1.5rem; } }
  @media screen and (max-width: 767px) {
    .section_subtitle {
      font-size: 1.3rem; } }

.section_descr {
  width: 100%;
  color: #777777;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6; }
  @media screen and (max-width: 1200px) {
    .section_descr {
      font-size: 1.3rem; } }
  .section_descr p {
    margin-bottom: 2rem; }
    .section_descr p:last-child {
      margin-bottom: 0; }
  .section_descr.center_text_mod {
    text-align: center; }
    @media screen and (max-width: 767px) {
      .section_form_block .section_descr.center_text_mod {
        text-align: left; } }
  .section_descr.white_mod {
    color: #ffffff; }
  .section_descr.color_mod {
    color: #555; }
  .section_descr.color_2_mod {
    color: #231717; }
  .section_descr.offset_mod {
    margin-bottom: 3.6rem; }
  .section_descr.size_mod {
    max-width: 48.2rem;
    margin: 0 auto 5.2rem;
    text-align: center; }
    @media screen and (max-width: 767px) {
      .section_form_block .section_descr.size_mod {
        max-width: none;
        margin: 0 0 2.8rem; } }
  .section_descr.work_mod {
    font-size: 1.7rem; }
    @media screen and (max-width: 1200px) {
      .section_descr.work_mod {
        font-size: 1.3rem; } }

.section_head {
  width: 100%; }
  .section_head.center_content_mod {
    align-items: center;
    align-content: center; }
  .section_head.offset_mod {
    margin-bottom: 6rem; }
  .section_head.offset_2_mod {
    margin-bottom: 3.8rem; }

.section_head_link {
  display: inline-flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-decoration: none;
  color: #777777;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase; }
  .section_head_link:hover, .section_head_link:active, .section_head_link:focus {
    text-decoration: none;
    color: #BB0B2F; }

@media screen and (min-width: 768px) {
  .section_head.v1_mod .section_head_col:first-child {
    width: 34.6%;
    margin-right: 2rem; } }

@media screen and (max-width: 767px) {
  .section_head.v1_mod .section_head_col:first-child {
    width: 100%;
    max-width: 29.6rem; } }

.section_head.v2_mod .section_head_col:first-child {
  max-width: 32rem;
  margin-right: 4rem; }

@media screen and (min-width: 1024px) {
  .section_head.v3_mod .section_head_col:first-child {
    max-width: 70rem;
    margin-right: 4rem; } }

@media screen and (max-width: 1023px) {
  .section_head.v3_mod .section_head_col:first-child {
    width: 100%;
    margin-bottom: 2rem; } }

.section_head.v1_mod .section_head_col:last-child {
  width: 56%;
  margin-left: auto; }
  @media screen and (max-width: 767px) {
    .section_head.v1_mod .section_head_col:last-child {
      display: none; } }

.section_bg_w {
  z-index: -1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%; }
  .section_bg_w:not(.pos_mod) {
    top: 0; }
  .section_bg_w.gradient_mod:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-image: linear-gradient(110.43deg, rgba(187, 11, 47, 0.9) 5.8%, rgba(129, 23, 67, 0.9) 40.61%, rgba(103, 28, 76, 0.9) 65.98%, rgba(0, 48, 112, 0.9) 100%); }
  .section_bg_w.gradient_2_mod {
    z-index: 1; }
    .section_bg_w.gradient_2_mod:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #efece9 0%, rgba(255, 255, 255, 0) 40%); }
  .section_bg_w.pos_mod {
    bottom: 0; }
    @media screen and (min-width: 1025px) {
      .section_bg_w.pos_mod {
        height: 54.10%; } }
    @media (min-width: 481px) and (max-width: 1024px) {
      .section_bg_w.pos_mod {
        height: 24rem; } }
    @media screen and (max-width: 480px) {
      .section_bg_w.pos_mod {
        height: 12rem; } }
  .section_bg_w.pos_2_mod {
    top: 3.6rem;
    height: (); }
  .section_bg_w.overlay_mod:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: #402200;
    opacity: .3; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .project_slider_item .section_bg_w {
      height: 50rem; } }
  @media screen and (max-width: 767px) {
    .project_slider_item .section_bg_w {
      height: 20rem; } }

.cover_bg {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover; }

.section_banner_content {
  width: 100%;
  max-width: 44.6rem; }

.icon_w {
  position: relative;
  display: block; }
  .icon_w.subscribe_mod {
    width: 2.4rem;
    height: 2.4rem;
    color: #d3002b; }
    @media screen and (min-width: 768px) {
      .icon_w.subscribe_mod {
        position: absolute;
        right: 100%;
        margin-right: 2.8rem; } }
    @media screen and (max-width: 767px) {
      .icon_w.subscribe_mod {
        margin: 0 auto 1.2rem; } }
  .icon_w.copy_mod {
    width: 1.7rem;
    height: 1.9rem;
    margin-right: 1.4rem;
    color: #555555; }
    @media screen and (max-width: 640px) {
      .icon_w.copy_mod {
        margin: 0;
        width: 4.6rem;
        height: 5rem;
        order: 2; } }
  .icon_w.dev_mod {
    width: 4rem;
    height: 4rem;
    margin-right: 2rem;
    color: #222222; }
    @media screen and (max-width: 1024px) {
      .icon_w.dev_mod {
        width: 3rem;
        height: 3rem;
        margin-right: 1rem; } }
  .icon_w.btn_decor_mod {
    width: 1.2rem;
    height: 1.1rem;
    margin-left: 2.4rem; }
  .icon_w.arrow_link_mod {
    width: 5.5rem;
    height: 1.6rem;
    margin-left: 1.8rem; }
  .icon_w.search_mod {
    position: absolute;
    top: 50%;
    right: 0;
    width: 2.4rem;
    height: 2.4rem;
    margin-top: -1.2rem;
    color: #fff;
    opacity: .3; }
  .icon_w.social_decor_mod {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5; }
    .header_social:hover .icon_w.social_decor_mod, .header_social:active .icon_w.social_decor_mod, .header_social:focus .icon_w.social_decor_mod {
      opacity: 1; }
  .icon_w.play_mod {
    z-index: 10;
    width: 1rem;
    height: 1.3rem;
    color: #d3002b; }

.icon.size_mod {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@media screen and (min-width: 1601px) {
  .hero_content {
    padding-top: 4.6rem; } }

@media screen and (min-width: 1024px) {
  .hero_content {
    width: 30.4%;
    margin-right: 2rem; } }

@media screen and (max-width: 1023px) {
  .hero_content {
    width: 100%; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .hero_content {
    margin-bottom: 4rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    align-content: flex-end; } }

@media screen and (max-width: 767px) {
  .hero_content {
    margin-bottom: 2.8rem; } }

.hero_content_in {
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .hero_content_in {
      margin-bottom: 3.6rem; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .hero_content_in {
      max-width: 41rem;
      margin-right: auto; } }
  @media screen and (max-width: 767px) {
    .hero_content_in {
      margin-bottom: 2.4rem; } }

@media screen and (min-width: 1024px) {
  .hero_slider_block {
    width: 62%;
    margin-left: auto; } }

@media screen and (max-width: 1023px) {
  .hero_slider_block {
    width: 100%; } }

.hero_slider_block_top {
  position: relative;
  width: 100%;
  margin-bottom: 3.4rem; }

.hero_decor {
  position: absolute;
  top: -6rem;
  right: -6rem;
  width: 100%; }
  .hero_decor:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(156, 12, 24, 0.5); }
  .hero_decor:after {
    position: absolute;
    top: 4rem;
    right: 4rem;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff; }
  @media screen and (max-width: 1023px) {
    .hero_decor {
      display: none; } }

.hero_slider {
  z-index: 10;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #fff; }

.hero_slider_item {
  overflow: hidden;
  margin-right: -1px; }

.hero_slider_item_in {
  position: relative;
  width: 100%;
  height: 42rem;
  padding: 2.6rem 3rem; }
  @media (min-width: 1024px) and (max-width: 1600px) {
    .hero_slider_item_in {
      height: 32rem; } }
  @media (min-width: 640px) and (max-width: 1023px) {
    .hero_slider_item_in {
      height: 30rem; } }
  @media screen and (max-width: 480px) {
    .hero_slider_item_in {
      height: 22rem;
      padding: .8rem 1.2rem; } }

.hero_slider_text {
  width: 100%;
  padding: 2.2rem;
  color: #000;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.3;
  background-color: rgba(255, 255, 255, 0.9); }
  .hero_slider_text span {
    color: #d3002b; }
  @media screen and (min-width: 480px) {
    .hero_slider_text {
      max-width: 31rem; } }
  @media screen and (max-width: 1024px) {
    .hero_slider_text {
      font-size: 1.5rem; } }

@media screen and (max-width: 767px) {
  .hero_slider_control_w {
    justify-content: center; } }

.slider_control {
  position: relative;
  display: block;
  width: 7.7rem;
  height: 2rem;
  color: #000;
  cursor: pointer; }
  .slider_control:hover, .slider_control:active, .slider_control:focus {
    color: #d3002b; }
  .slider_control:first-child {
    margin-right: 3.2rem; }
  @media screen and (max-width: 767px) {
    .slider_control {
      width: 5rem;
      height: 1.2rem; } }

.step_block {
  width: 100%;
  max-width: 99.8rem; }
  @media screen and (max-width: 1600px) {
    .step_block {
      margin: 0 auto; } }

.step_block_list {
  padding: 0;
  list-style: none; }
  @media screen and (min-width: 1024px) {
    .step_block_list {
      margin: 0 -10rem -2rem 0; } }

.step_block_item {
  z-index: 2;
  position: relative;
  width: 33.33%; }
  .step_block_item:nth-child(even) {
    z-index: 1; }
  @media screen and (min-width: 1024px) {
    .step_block_item {
      padding: 2rem 10rem 2rem 0; } }

.item_decor_line_w {
  position: absolute;
  top: 3.4rem;
  left: 50%;
  width: calc(100% + .4rem); }
  @media screen and (min-width: 1024px) {
    .item_decor_line_w {
      transform: translateX(calc(-50% - 5rem)); } }
  @media screen and (max-width: 1023px) {
    .item_decor_line_w {
      top: 1.6rem;
      transform: translateX(-50%); } }

.item_decor_line {
  width: 50%; }
  .item_decor_line span {
    display: block;
    width: 0.4rem;
    height: 0.4rem;
    background-color: #000000; }
    @media screen and (min-width: 1024px) {
      .step_block_item:hover .item_decor_line span {
        background-color: #d3002b; } }
    @media screen and (max-width: 1023px) {
      .step_block_item.active_tab .item_decor_line span {
        background-color: #d3002b; } }
  @media screen and (max-width: 767px) {
    .item_decor_line:first-child span:nth-child(2) {
      display: none; } }
  .item_decor_line:last-child {
    margin-left: auto; }
    @media screen and (max-width: 767px) {
      .item_decor_line:last-child span:nth-child(2) {
        display: none; } }
  .step_block_item:first-child .item_decor_line:first-child {
    display: none; }
  .step_block_item:last-child .item_decor_line:last-child {
    display: none; }

.step_block_item_in {
  width: 100%;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .step_block_item:first-child .step_block_item_in {
      align-items: flex-start;
      align-content: flex-start; }
    .step_block_item:last-child .step_block_item_in {
      align-items: flex-end;
      align-content: flex-end; } }

.step_block_item_head {
  width: 100%; }

.step_block_number {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 3.4rem;
  height: 3.4rem;
  margin-bottom: 2.4rem;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 3.4rem;
  background-color: #000000;
  border-radius: 50%;
  transition: transform .3s ease, background-color .3s ease, color .3s ease;
  will-change: transform; }
  @media screen and (min-width: 1024px) {
    .step_block_item_in:hover .step_block_number {
      background-color: #d3002b;
      color: #fff;
      transform: scale(1.52); } }
  @media screen and (max-width: 1023px) {
    .step_block_item.active_tab .step_block_number {
      background-color: #d3002b;
      color: #fff;
      transform: scale(1.52); } }
  @media screen and (max-width: 767px) {
    .step_block_item.active_tab .step_block_number {
      transform: none; } }

.step_block_title {
  margin-bottom: 1.8rem;
  color: #000000;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.4; }
  @media screen and (min-width: 1024px) {
    .step_block_item_in:hover .step_block_title {
      color: #d3002b; } }
  @media screen and (max-width: 1023px) {
    .step_block_item.active_tab .step_block_title {
      color: #d3002b; } }
  @media (min-width: 1024px) and (max-width: 1200px) {
    .step_block_title {
      font-size: 1.8rem; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .step_block_title {
      font-size: 1.5rem; } }
  @media screen and (max-width: 767px) {
    .step_block_title {
      font-size: 1.8rem;
      display: none; }
      .step_block_item.active_tab .step_block_title {
        display: block; } }

@media screen and (min-width: 1024px) {
  .step_block_descr_w {
    opacity: 0;
    pointer-events: none; } }

.step_block_item_in:hover .step_block_descr_w {
  opacity: 1;
  pointer-events: auto; }

@media screen and (max-width: 1023px) {
  .step_block_item_in .step_block_descr_w {
    display: none; } }

.step_block_descr {
  margin-bottom: 1.8rem;
  color: #000000;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.4; }
  .step_block_descr p {
    margin-bottom: 2rem; }
    .step_block_descr p:last-child {
      margin-bottom: 0; }

.more_link {
  display: inline-flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  text-decoration: none;
  color: #003070;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4; }
  .more_link:hover, .more_link:active, .more_link:focus {
    text-decoration: none; }

.steps_text_list {
  width: 100%;
  max-width: 55rem;
  margin: 3rem auto 0; }
  @media screen and (min-width: 1024px) {
    .steps_text_list {
      display: none; } }
  @media screen and (max-width: 767px) {
    .steps_text_list {
      margin-top: 0; } }

.steps_text_item {
  width: 100%;
  display: none; }
  .steps_text_item.active_tab {
    display: block; }
  @media screen and (min-width: 768px) {
    .steps_text_item {
      text-align: center; } }

@media screen and (min-width: 1024px) {
  .project_slider_w {
    margin: 0 -6rem; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .project_slider_w {
    margin: 0 -2.4rem; } }

@media screen and (max-width: 767px) {
  .project_slider_w {
    margin: 0 -1.2rem; } }

.project_slider {
  width: 100%; }
  .project_slider .slick-prev {
    display: none !important; }
  @media screen and (min-width: 1025px) {
    .project_slider.single_mod {
      height: 64rem; } }
  .project_slider.single_mod .slick-track,
  .project_slider.single_mod .slick-list {
    height: 100%; }

.project_slider_item {
  position: relative;
  width: 100%; }

@media screen and (min-width: 1025px) {
  .project_slider_item_content {
    height: 100vh;
    min-height: 72.6rem; } }

.project_info_w {
  width: 100%; }
  @media (min-width: 768px) and (max-width: 1600px) {
    .project_info_w {
      padding: 0 6rem;
      position: relative; }
      .project_info_w:before {
        z-index: -1;
        position: absolute;
        top: 3rem;
        bottom: 0;
        left: 0;
        content: '';
        width: 100%;
        background-color: #000; } }
  @media screen and (max-width: 767px) {
    .project_info_w {
      padding: 0; } }

.project_info_col:first-child {
  width: 71.6%;
  background-color: #fff; }
  @media screen and (max-width: 1023px) {
    .project_info_col:first-child {
      width: calc(100% - 8rem); } }
  @media screen and (max-width: 767px) {
    .project_info_col:first-child {
      width: 100%; } }

.project_info_col:last-child {
  position: relative;
  width: 28.4%; }
  @media screen and (max-width: 1023px) {
    .project_info_col:last-child {
      width: 8rem; } }
  @media screen and (max-width: 767px) {
    .project_info_col:last-child {
      display: none; } }

.project_control_bg_w {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .project_control_bg_w:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .5; }

.project_control_w {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%; }

.project_control {
  position: relative;
  width: 7.7rem;
  height: 2rem;
  margin-bottom: 1.2rem;
  color: #fff;
  cursor: pointer; }
  @media screen and (max-width: 1023px) {
    .project_control {
      width: 5.1rem;
      height: 1.2rem; } }

.project_control_title {
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4; }
  @media screen and (max-width: 1023px) {
    .project_control_title {
      display: none; } }

.project_text_slider_item_in {
  width: 100%;
  min-height: 19.6rem;
  padding: 4.8rem 4.8rem 4.8rem 2.8rem; }

@media screen and (min-width: 768px) {
  .project_info_title_w {
    width: 26.8%;
    margin-right: 2rem; } }

@media screen and (max-width: 767px) {
  .project_info_title_w {
    width: 100%;
    margin-bottom: 1.2rem; } }

.project_info_text_w {
  width: 60.46%;
  margin-left: auto; }
  @media (min-width: 768px) and (max-width: 1024px) {
    .project_info_text_w {
      width: 68%; } }
  @media screen and (max-width: 767px) {
    .project_info_text_w {
      width: 100%; } }

.project_info_subtitle {
  width: 100%;
  color: #d3002b;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase; }

.project_info_title {
  width: 100%;
  color: #000000;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase; }
  @media screen and (max-width: 1024px) {
    .project_info_title {
      font-size: 2.2rem; } }

.project_info_text {
  width: 100%;
  margin-bottom: 1rem;
  color: #555555;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4; }
  .project_info_text p {
    margin-bottom: 2rem; }
    .project_info_text p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 1024px) {
    .project_info_text {
      font-size: 1.3rem; } }

.project_link {
  text-decoration: none;
  color: #003070;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.4; }
  .project_link:hover, .project_link:active, .project_link:focus {
    color: #d3002b; }
  @media screen and (max-width: 1024px) {
    .project_link {
      font-size: 1.3rem; } }

.project_slider_item_in {
  z-index: 3;
  width: 100%;
  pointer-events: none; }
  @media screen and (min-width: 1025px) {
    .project_slider_item_in {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; } }
  @media (min-width: 768px) and (max-width: 1024px) {
    .project_slider_item_in {
      position: relative;
      height: 50rem;
      margin-bottom: -3rem; } }
  @media screen and (max-width: 767px) {
    .project_slider_item_in {
      position: relative;
      height: 20rem; } }

.project_slider_item_info {
  position: absolute;
  top: 50%;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  white-space: nowrap;
  cursor: pointer;
  pointer-events: auto;
  background-color: #000; }
  .project_slider_item_info:before {
    z-index: -1;
    position: absolute;
    top: 50%;
    left: 50%;
    content: '';
    width: 5.6rem;
    height: 5.6rem;
    margin: -2.8rem 0 0 -2.8rem;
    background-color: #BB0B2F;
    border-radius: 50%;
    opacity: .3; }
    @media screen and (max-width: 767px) {
      .project_slider_item_info:before {
        width: 3.6rem;
        height: 3.6rem;
        margin: -1.8rem 0 0 -1.8rem; } }
  .project_slider_item_info.pos_1_mod {
    left: 18%;
    margin: -20rem 0 0 0; }
    @media screen and (max-width: 767px) {
      .project_slider_item_info.pos_1_mod {
        left: 10%;
        margin: -1.2rem 0 0 0; } }
  .project_slider_item_info.pos_2_mod {
    left: 50%;
    margin: 4rem 0 0 -8.8rem; }
    @media screen and (max-width: 767px) {
      .project_slider_item_info.pos_2_mod {
        margin: 4rem 0 0 -1.2rem; } }
  .project_slider_item_info.pos_3_mod {
    right: 21%;
    margin: -17rem 0 0 47.6rem; }
    @media screen and (max-width: 767px) {
      .project_slider_item_info.pos_3_mod {
        right: 10%;
        margin: -1.2rem 0 0 0; } }
  @media screen and (max-width: 767px) {
    .project_slider_item_info {
      top: 30%;
      width: 2.4rem;
      height: 2.4rem; } }

.item_info_decor {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1.2rem;
  height: 1.2rem;
  margin: -.6rem 0 0 -.6rem;
  color: #fff; }

.project_slider_item_info_in {
  position: absolute;
  top: 100%;
  left: 50%;
  width: 34.8rem;
  min-height: 11.2rem;
  margin: 2.6rem 0 0 -17.4rem;
  padding: 2.4rem 1.8rem;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4;
  white-space: normal;
  background-color: black;
  pointer-events: none;
  opacity: 0; }
  @media screen and (max-width: 768px) {
    .project_slider_item_info_in {
      font-size: 1.3rem; } }
  .project_slider_item_info_in:before {
    position: absolute;
    top: 0;
    left: 50%;
    content: '';
    width: 2rem;
    height: 2rem;
    margin: -.6rem 0 0 -1rem;
    transform: rotate(45deg);
    background-color: black; }
    @media (min-width: 768px) and (max-width: 1023px) {
      .project_slider_item_info:last-child .project_slider_item_info_in:before {
        left: auto;
        right: 1rem;
        margin-left: 0; } }
    @media (min-width: 768px) and (max-width: 1023px) {
      .project_slider_item_info:first-child .project_slider_item_info_in:before {
        left: 1rem;
        margin-left: 0; } }
    @media screen and (max-width: 767px) {
      .project_slider_item_info_in:before {
        display: none; } }
  .project_slider_item_info:hover .project_slider_item_info_in {
    opacity: 1; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .project_slider_item_info:first-child .project_slider_item_info_in {
      margin-left: -2.2rem; }
    .project_slider_item_info:last-child .project_slider_item_info_in {
      margin-left: -32.4rem; } }
  @media screen and (max-width: 767px) {
    .project_slider_item_info_in {
      position: static;
      width: 100%;
      opacity: 1;
      pointer-events: auto;
      margin: 0; }
      .project_slider_item_info .project_slider_item_info_in {
        display: none; }
      .project_slider_tabs_item .project_slider_item_info_in {
        display: none; }
      .project_slider_tabs_item.active_project .project_slider_item_info_in {
        display: block; } }

.project_slider_bottom:not(.single_mod) {
  margin-bottom: -3.6rem; }
  @media screen and (max-width: 767px) {
    .project_slider_bottom:not(.single_mod) {
      margin-bottom: 0; } }

.project_slider_dots {
  width: 100%;
  height: 3.6rem;
  background-color: #000; }

.section_form_block {
  width: 100%;
  max-width: 70rem; }

.section_form_bottom {
  width: 100%; }

.section_form_bottom_text {
  width: 66.66%;
  margin-right: auto; }
  @media screen and (max-width: 767px) {
    .section_form_bottom_text {
      order: 2;
      margin: 2.4rem 0 0; } }

.section_form_bottom_text_in {
  width: 100%;
  max-width: 36rem;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.2; }
  .section_form_bottom_text_in a {
    text-decoration: underline;
    color: rgba(255, 255, 255, 0.5); }

@media screen and (min-width: 1601px) {
  .news_list_w {
    margin-left: -18.6rem; } }

.news_list {
  padding: 0;
  list-style: none; }
  @media screen and (min-width: 1201px) {
    .news_list {
      margin: 0 -9.8rem -4rem 0; } }
  @media screen and (max-width: 1200px) {
    .news_list {
      margin: 0 -3rem -3rem 0; } }

.news_item {
  padding: 0 9.8rem 4rem 0; }
  @media screen and (min-width: 1024px) {
    .news_item {
      width: 33.33%; } }
  @media screen and (max-width: 1023px) {
    .news_item {
      width: 100%; } }
  @media screen and (min-width: 1201px) {
    .news_item {
      padding: 0 9.8rem 4rem 0; } }
  @media screen and (max-width: 1200px) {
    .news_item {
      padding: 0 3rem 3rem 0; } }

.news_item_in {
  width: 100%; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .news_item_in {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start; } }

.news_item_top {
  position: relative;
  width: 100%;
  margin-bottom: 2.6rem;
  padding: 1.2rem 1.2rem 0 0; }
  .news_item_top:before {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    width: 17.4rem;
    height: 17.4rem;
    background-color: rgba(107, 128, 155, 0.2); }
    @media (min-width: 768px) and (max-width: 1023px) {
      .news_item_top:before {
        height: 10rem; } }
    @media screen and (max-width: 767px) {
      .news_item_top:before {
        height: 12rem; } }
  @media (min-width: 768px) and (max-width: 1023px) {
    .news_item_top {
      max-width: 28.3rem;
      margin-right: 3rem; } }

.news_item_img_w {
  width: 100%;
  height: 18.4rem;
  border: 1rem solid #fff;
  border-left: 0; }
  @media (min-width: 768px) and (max-width: 1023px) {
    .news_item_img_w {
      height: 12.6rem; } }
  @media screen and (max-width: 480px) {
    .news_item_img_w {
      height: 14rem; } }

.news_item_text_w {
  width: 100%; }

.news_item_title {
  width: 100%;
  margin-bottom: 1.4rem;
  color: #000000;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.4; }
  @media screen and (max-width: 1024px) {
    .news_item_title {
      font-size: 1.5rem; } }

.news_item_descr {
  width: 100%;
  margin-bottom: 4rem;
  color: #777777;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.4; }
  .news_item_descr p {
    margin-bottom: 2rem; }
    .news_item_descr p:last-child {
      margin-bottom: 0; }
  @media screen and (max-width: 1024px) {
    .news_item_descr {
      font-size: 1.3rem; } }
  @media screen and (max-width: 767px) {
    .news_item_descr {
      margin-bottom: 1.8rem; } }

.brand_slider {
  margin: 0 -6rem; }
  .brand_slider .slick-track {
    display: flex;
    align-items: center;
    align-content: center; }

.brand_slider_item {
  width: auto;
  padding: 0 3em; }
  @media screen and (max-width: 480px) {
    .brand_slider_item {
      padding: 0 1rem; } }

.brand_slider_item_in {
  width: 100%;
  height: 100%; }

.brand_slider_img {
  display: block;
  max-width: 100%;
  max-height: 5.5em; }

@media screen and (min-width: 1024px) {
  .portfolio_list_w {
    margin-left: -2.8rem; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .portfolio_list_w {
    margin: 0 -2.4rem; } }

@media screen and (max-width: 767px) {
  .portfolio_list_w {
    margin: 0 -1.2rem; } }

.portfolio_list {
  margin: 0;
  padding: 0;
  list-style: none; }

.portfolio_item {
  width: 100%;
  margin-bottom: 5.4rem; }
  .portfolio_item:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 1023px) {
    .portfolio_item {
      margin-bottom: 0;
      padding-bottom: 2rem;
      background-color: #000; } }

.portfolio_item_in {
  position: relative;
  width: 100%; }
  .portfolio_item_in:before {
    position: absolute;
    top: 100%;
    left: 0;
    content: '';
    width: 100%;
    height: 1.8rem;
    background-color: #000; }
  @media screen and (min-width: 1024px) {
    .portfolio_item_in {
      min-height: 58.5rem; } }

@media screen and (min-width: 1024px) {
  .portfolio_img_block {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    right: -12rem;
    bottom: 0; } }

@media screen and (max-width: 1023px) {
  .portfolio_img_block {
    position: relative;
    height: 30rem;
    margin-bottom: -3rem; } }

.portfolio_img_block:before {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(64, 34, 0, 0) 0%, rgba(64, 34, 0, 0.5) 100%); }

.portfolio_img_block_in {
  width: 100%;
  height: 100%; }

.work_year_info {
  opacity: 0.2;
  font-weight: 700;
  text-transform: uppercase; }
  @media screen and (min-width: 1024px) {
    .work_year_info {
      margin-left: auto;
      font-size: 3.8rem; } }
  @media screen and (max-width: 1023px) {
    .work_year_info {
      font-size: 2.8rem; } }

.work_info_w.offset_mod {
  margin-bottom: 6rem; }

@media screen and (min-width: 1024px) {
  .work_info_col:first-child {
    width: 31.4em;
    margin-right: 1rem; } }

@media screen and (max-width: 1023px) {
  .work_info_col:first-child {
    width: 100%;
    margin-bottom: 2rem; } }

@media screen and (min-width: 1024px) {
  .work_info_col:last-child {
    width: 58.8%;
    margin-left: auto; } }

@media screen and (max-width: 1023px) {
  .work_info_col:last-child {
    width: 100%; } }

.work_info_list {
  margin: 0;
  padding: 0;
  list-style: none; }

.work_info_item {
  margin-bottom: 2rem;
  color: #003070;
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase; }
  .work_info_item:last-child {
    margin-bottom: 0; }

@media screen and (min-width: 1024px) {
  .work_img_w {
    margin: 0 0 10rem -2.8rem; } }

@media screen and (max-width: 1023px) {
  .work_img_w {
    margin-bottom: 6rem; } }

.work_img {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

@media screen and (min-width: 1024px) {
  .work_slider_w {
    margin: 0 -22.3vw 3.4rem -2.8rem; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .work_slider_w {
    margin: 0 -22.3vw 3.4rem -2.4rem; } }

@media screen and (max-width: 767px) {
  .work_slider_w {
    margin: 0 -1.2rem 3.4rem; } }

.work_slider {
  width: 100%; }

@media screen and (min-width: 768px) {
  .work_slider_item {
    padding-right: 2.8rem; } }

.work_slider_item_in {
  width: 100%; }
  @media screen and (min-width: 1024px) {
    .work_slider_item_in {
      height: 58rem; } }
  @media screen and (max-width: 1023px) {
    .work_slider_item_in {
      height: 40rem; } }

.work_slider_counter {
  position: relative;
  width: 6rem;
  height: 6rem;
  white-space: nowrap; }

.work_slider_counter_in {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #000;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.43;
  text-transform: uppercase;
  transform: translate(-50%, -50%); }
  @media screen and (max-width: 767px) {
    .work_slider_counter_in {
      font-size: 2rem; } }

.work_slider_control_w {
  margin-left: auto; }

.other_work_list {
  width: 100%; }

.other_work_item {
  width: 100%;
  margin-bottom: 4rem; }
  .other_work_item:last-child {
    margin-bottom: 0; }

@media screen and (min-width: 768px) {
  .other_work_item_col:first-child {
    width: 20%; } }

@media screen and (max-width: 767px) {
  .other_work_item_col:first-child {
    width: 100%;
    margin-bottom: 2rem; } }

@media screen and (min-width: 768px) {
  .other_work_item_col:last-child {
    width: 71.5%;
    margin-left: auto; } }

@media screen and (max-width: 767px) {
  .other_work_item_col:last-child {
    width: 100%; } }

.other_work_img_w {
  position: relative;
  width: 100%;
  height: 18rem; }
  .other_work_img_w:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(64, 34, 0, 0.3), rgba(64, 34, 0, 0.3)); }
  .other_work_img_w:after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0;
    background: linear-gradient(123.62deg, rgba(187, 11, 47, 0.5) 5.8%, rgba(129, 23, 67, 0.5) 40.61%, rgba(103, 28, 76, 0.5) 65.98%, rgba(0, 48, 112, 0.5) 100%); }
  .other_work_img_w:hover:before, .other_work_img_w:active:before, .other_work_img_w:focus:before {
    opacity: 0; }
  .other_work_img_w:hover:after, .other_work_img_w:active:after, .other_work_img_w:focus:after {
    opacity: 1; }
  .other_work_img_w .project_control_w {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.other_work_subtitle {
  color: #d3002b;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase; }

.other_work_title {
  color: #000000;
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase; }
  @media screen and (max-width: 1023px) {
    .other_work_title {
      font-size: 2.4rem; } }

@font-face {
  font-family: "iconfont";
  src: url("../fonts/icons/iconfont.eot");
  src: url("../fonts/icons/iconfont.eot?#iefix") format("eot"), url("../fonts/icons/iconfont.woff") format("woff"), url("../fonts/icons/iconfont.ttf") format("truetype"), url("../fonts/icons/iconfont.svg#iconfont") format("svg"); }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-arrow_l {
  font-size: 2rem;
  width: 3.85em; }

.icon-arrow_r {
  font-size: 2rem;
  width: 3.85em; }

.icon-bell {
  font-size: 2.8rem;
  width: 0.91643em; }

.icon-copy_logo {
  font-size: 1.784rem;
  width: 0.95516em; }

.icon-dev_logo {
  font-size: 4.166rem;
  width: 0.93807em; }

.icon-facebook {
  font-size: 1.6rem;
  width: 0.51938em; }

.icon-instagram {
  font-size: 1.6rem;
  width: 1em; }

.icon-play {
  font-size: 1.126rem;
  width: 0.8659em; }

.icon-plus {
  font-size: 1.3rem;
  width: 1em; }

.icon-question {
  font-size: 1.5rem;
  width: 0.6em; }

.icon-search {
  font-size: 2.286rem;
  width: 1em; }

.icon-shevron {
  font-size: 0.957rem;
  width: 1.17973em; }

.icon-sozial_decor {
  font-size: 3.8rem;
  width: 1em; }

.icon-sozial_decor_2 {
  font-size: 3.85rem;
  width: 0.98701em; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  width: 100%; }
  .slick-dots li {
    padding: 0 2.8rem; }
    .slick-dots li.slick-active button {
      background-color: #BB0B2F; }
  .slick-dots button {
    display: block;
    width: .4rem;
    height: .4rem;
    font-size: 0;
    cursor: pointer;
    background-color: #fff; }
    .slick-dots button:hover, .slick-dots button:focus {
      background-color: #fff; }
