//---------------------------------------------fonts
$font_path: "../fonts/";
$font_1: 'Montserrat', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;

$accent: #BB0B2F;

$gradient: linear-gradient(NaNdeg, #bb0b2f 3%, #811743 39%, #671c4c 66%, #003070 100%);
$gradient_2: linear-gradient(110.43deg, rgba(187, 11, 47, 0.9) 5.8%, rgba(129, 23, 67, 0.9) 40.61%, rgba(103, 28, 76, 0.9) 65.98%, rgba(0, 48, 112, 0.9) 100%);
$gradient_3: linear-gradient(NaNdeg, #efece9 0%, rgba(239, 236, 233, 0) 100%);
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 38rem;
$height_header: 7.2rem;
$height_header_2: 6rem;
$page_width: 147rem;
$page_width_2: 136rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 6rem;
$tablet_indent: 2.4rem;
$mobile_indent: 1.2rem;
$gl_radius:0;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
$point_9: 1600px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height: 4.8rem;
$input_offset: 0 2rem;
$input_font_size: 1.5rem;
$input_border_color: #fff;
$input_border_color_active: $black;
$input_placeholder_color: #fff;
$input_text_color: #fff;
$input_radius: $gl_radius;
//---------------------------------------------forms###