//-------------------------------------------------------inputs and selects
%form_field_default {
	display: block;
	width: 100%;
	height: $input_height;
	padding: $input_offset;
	font-size: $input_font_size;
	font-family: $font_1;
	color: $input_text_color;
	box-shadow: none;
	border: 1px solid rgba($input_border_color, .3);
	border-radius: $input_radius;
	background-color: transparent;

	&::-webkit-input-placeholder {
		color: $input_placeholder_color;
		opacity: .3;
	}

	&::-moz-placeholder {
		color: $input_placeholder_color;
		opacity: .3;
	}

	&:-ms-input-placeholder {
		color: $input_placeholder_color;
		opacity: .3;
	}

}

%form_field_default_hover {
}

//%form_field_default_active {
//	box-shadow: 0 0 0 1px $input_border_color_active;
//}

.form_cell {
	&.hidden_field {
		display: none;
	}

	&.form_cell_v1_mod {
	}
}

.form_cell_title {

	&.hline_hide_mod {
		display: none;
	}

	&.form_v1_mod {
	}

}

.form_field_wrap {
	position: relative;

	&.form_v1_mod {
	}

}

.form_field {
	&.default_mod {
		@extend %form_field_default !optional;

		&:focus {
			@extend %form_field_default_active !optional;
		}

		&:hover {
			@extend %form_field_default_hover !optional;
		}
	}

	&.textarea_mod_1 {
	}

}

//---------------------------------------------inputs and selects###

//---------------------------------------------checkboxes and radio-buttons

// copy/paste from helpers/components/forms/_form.scss

//---------------------------------------------checkboxes and radio-buttons###