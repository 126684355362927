.project_slider_w {
	@include bp($point_2, min) {
		margin: 0 (-$gl_indent);
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		margin: 0 (-$tablet_indent);
	}

	@include bp($point_4 - 1) {
		margin: 0 (-$mobile_indent);
	}
}

.project_slider {
	width: 100%;

	.slick-prev {
		display: none !important;
	}

	&.single_mod {
		@include bp($point_2 + 1, min) {
			height: 64rem;
		}

		.slick-track,
		.slick-list {
			height: 100%;
		}

	}
}

.project_slider_item {
	position: relative;
	width: 100%;
}

.project_slider_item_content {
	@extend %global_flex_block_row_wrap_flex-start_flex-end;

	@include bp($point_2 + 1, min) {
		height: 100vh;
		min-height: 72.6rem;
	}
}

.project_info_w {
	@extend %global_flex_block_row_wrap_flex-start_stretch;

	width: 100%;

	@media (min-width: $point_4) and (max-width: $point_9) {
		padding: 0 6rem;
		position: relative;

		&:before {
			z-index: -1;

			position: absolute;
			top: 3rem;
			bottom: 0;
			left: 0;

			content: '';

			width: 100%;

			background-color: $black;
		}
	}

	@include bp($point_4 - 1) {
		padding: 0;
	}
}

.project_info_col {
	&:first-child {
		@extend %global_flex_block_row_wrap_flex-start;

		width: 71.6%;
		background-color: $white;

		@include bp($point_3) {
			width: calc(100% - 8rem);
		}

		@include bp($point_4 - 1) {
			width: 100%;
		}
	}

	&:last-child {
		position: relative;

		width: 28.4%;

		@include bp($point_3) {
			width: 8rem;
		}

		@include bp($point_4 - 1) {
			display: none;
		}
	}
}

.project_control_bg_w {
	z-index: 1;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	&:before {
		position: absolute;
		top: 0;
		left: 0;

		content: '';

		width: 100%;
		height: 100%;

		background-color: $black;

		opacity: .5;
	}
}

.project_control_w {
	@extend %global_flex_block_column_nowrap_center_center;

	z-index: 2;

	position: relative;

	width: 100%;
	height: 100%;
}

.project_control {
	position: relative;

	width: 7.7rem;
	height: 2rem;
	margin-bottom: 1.2rem;

	color: $white;

	cursor: pointer;

	@include bp($point_3) {
		width: 5.1rem;
		height: 1.2rem;
	}
}

.project_control_title {
	color: #ffffff;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.4;

	@include bp($point_3) {
		display: none;
	}
}

.project_text_slider_item_in {
	@extend %global_flex_block_row_wrap_flex-start;

	width: 100%;
	min-height: 19.6rem;
	padding: 4.8rem 4.8rem 4.8rem 2.8rem;
}

.project_info_title_w {
	@include bp($point_4, min) {
		width: 26.8%;
		margin-right: 2rem;
	}

	@include bp($point_4 - 1) {
		width: 100%;
		margin-bottom: 1.2rem;
	}
}

.project_info_text_w {
	width: 60.46%;
	margin-left: auto;

	@media (min-width: $point_4) and (max-width: $point_2) {
		width: 68%;
	}

	@include bp($point_4 - 1) {
		width: 100%;
	}
}

.project_info_subtitle {
	width: 100%;

	color: #d3002b;
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 1.4;
	text-transform: uppercase;
}

.project_info_title {
	width: 100%;

	color: #000000;
	font-size: 2.8rem;
	font-weight: 500;
	line-height: 1.4;
	text-transform: uppercase;

	@include bp($point_2) {
		font-size: 2.2rem;
	}
}

.project_info_text {
	width: 100%;
	margin-bottom: 1rem;

	color: #555555;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.4;

	p {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include bp($point_2) {
		font-size: 1.3rem;
	}
}

.project_link {
	@extend %transition_color;
	
	text-decoration: none;
	color: #003070;
	font-size: 1.5rem;
	font-weight: 500;
	line-height: 1.4;

	&:hover, &:active, &:focus {
		color: #d3002b;
	}

	@include bp($point_2) {
		font-size: 1.3rem;
	}
}

.project_slider_item_in {
	z-index: 3;

	width: 100%;

	pointer-events: none;

	@include bp($point_2 + 1, min) {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}

	@media (min-width: $point_4) and (max-width: $point_2) {
		position: relative;
		height: 50rem;
		margin-bottom: -3rem;
	}

	@include bp($point_4 - 1) {
		position: relative;
		height: 20rem;
	}
}

.project_slider_item_info {
	position: absolute;
	top: 50%;

	width: 3.6rem;
	height: 3.6rem;

	border-radius: 50%;

	white-space: nowrap;

	cursor: pointer;

	pointer-events: auto;

	background-color: $black;

	&:before {
		z-index: -1;
		position: absolute;
		top: 50%;
		left: 50%;

		content: '';

		width: 5.6rem;
		height: 5.6rem;

		margin: (-5.6rem/2) 0 0 (-5.6rem/2);

		background-color: #BB0B2F;

		border-radius: 50%;

		opacity: .3;

		@include bp($point_4 - 1) {
			width: 3.6rem;
			height: 3.6rem;

			margin: (-3.6rem/2) 0 0 (-3.6rem/2);
		}
	}

	&.pos_1_mod {
		left: 18%;
		margin: -20rem 0 0 0;

		@include bp($point_4 - 1) {
			left: 10%;
			margin: (-2.4rem/2) 0 0 0;
		}
	}

	&.pos_2_mod {
		left: 50%;
		margin: 4rem 0 0 -8.8rem;

		@include bp($point_4 - 1) {
			margin: 4rem 0 0 (-2.4rem/2);
		}
	}

	&.pos_3_mod {
		right: 21%;
		margin: -17rem 0 0 47.6rem;

		@include bp($point_4 - 1) {
			right: 10%;
			margin: (-2.4rem/2) 0 0 0;
		}
	}

	@include bp($point_4 - 1) {
		top: 30%;
		width: 2.4rem;
		height: 2.4rem;
	}
}

.item_info_decor {
	position: absolute;
	top: 50%;
	left: 50%;

	width: 1.2rem;
	height: 1.2rem;
	margin: -.6rem 0 0 -.6rem;

	color: $white;
}

.project_slider_item_info_in {
	@extend %transition_opacity;

	position: absolute;
	top: 100%;
	left: 50%;

	width: 34.8rem;
	min-height: 11.2rem;
	margin: 2.6rem 0 0 (-34.8rem/2);
	padding: 2.4rem 1.8rem;

	color: #ffffff;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.4;

	white-space: normal;

	background-color: black;

	pointer-events: none;
	opacity: 0;

	@include bp($point_4) {
		font-size: 1.3rem;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 50%;

		content: '';

		width: 2rem;
		height: 2rem;
		margin: -.6rem 0 0 -1rem;

		transform: rotate(45deg);

		background-color: black;

		@media (min-width: $point_4) and (max-width: $point_3) {
			.project_slider_item_info:last-child & {
				left: auto;
				right: 1rem;
				margin-left: 0;
			}
		}

		@media (min-width: $point_4) and (max-width: $point_3) {
			.project_slider_item_info:first-child & {
				left: 1rem;
				margin-left: 0;
			}
		}

		@include bp($point_4 - 1) {
			display: none;
		}
	}

	.project_slider_item_info:hover & {
		opacity: 1;
	}

	@media (min-width: $point_4) and (max-width: $point_3) {
		.project_slider_item_info:first-child & {
			margin-left: -2.2rem;
		}

		.project_slider_item_info:last-child & {
			margin-left: -32.4rem;
		}
	}

	@include bp($point_4 - 1) {
		.project_slider_item_info & {
			display: none;
		}

		position: static;
		width: 100%;
		opacity: 1;
		pointer-events: auto;
		margin: 0;

		.project_slider_tabs_item & {
			display: none;
		}

		.project_slider_tabs_item.active_project & {
			display: block;
		}
	}
}

.project_slider_bottom {
	&:not(.single_mod) {
		margin-bottom: -3.6rem;

		@include bp($point_4 - 1) {
			margin-bottom: 0;
		}
	}
}

.project_slider_dots {
	@extend %global_flex_block_row_wrap_flex-start_center;

	width: 100%;
	height: 3.6rem;

	background-color: $black;
}